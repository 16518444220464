import {Price} from './fractions/price'
import {TokenAmount} from './fractions/tokenAmount'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
 import {keccak256, pack} from '@ethersproject/solidity'
 import {getCreate2Address} from '@ethersproject/address'
//import {Contract} from '@ethersproject/contracts'
//import {getDefaultProvider} from '@ethersproject/providers'
//import {getNetwork} from '@ethersproject/networks'
//import PairABI from '../../src/abis/Factory.json'
//import {utils} from 'zksync-web3'
//import * as ethers from "ethers";



import {
    _1000,
    _997,
    BigintIsh,
    ChainId,
    FACTORY_ADDRESS,
    FIVE,
    INIT_CODE_HASH,
    MINIMUM_LIQUIDITY,
    ONE,
    ZERO
} from '../constants'
import {parseBigintIsh, sqrt} from '../utils'
import {InsufficientInputAmountError, InsufficientReservesError} from '../errors'
import {Token} from './token'

let PAIR_ADDRESS_CACHE: { [token0Address: string]: { [token1Address: string]: string } } = {}

export class Pair {
  public readonly liquidityToken: Token
  private readonly tokenAmounts: [TokenAmount, TokenAmount]

  public static getAddress(tokenA: Token, tokenB: Token): string {
    const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

    //const calc_address2 = utils.create2Address(FACTORY_ADDRESS, utils.hashBytecode( [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]), INIT_CODE_HASH, '0x');
//    return calc_address2;
//    const prefix = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('zksyncCreate2'));
//    const addressBytes = ethers.utils.keccak256(ethers.utils.concat([prefix, ethers.utils.zeroPad(FACTORY_ADDRESS, 32), "0x", utils.hashBytecode( [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]), INIT_CODE_HASH])).slice(26);
//    return ethers.utils.getAddress(addressBytes);


    // //TK0-N
    // if((tokens[0].address == '0x4524C9135F64fd3981C159e78B7761aDD804F727' && tokens[1].address == '0xD3765838f9600Ccff3d01EFA83496599E0984BD2') || (tokens[1].address == '0x4524C9135F64fd3981C159e78B7761aDD804F727' && tokens[0].address == '0xD3765838f9600Ccff3d01EFA83496599E0984BD2')){
    //   return "0x8263e092CaC9946BD5D2b23FE9f24aCcA32cb0a2";
    // }



    const WETH0 = '0x79b71A6dbe48Cc5D9Ff741728F44322Ed63F0fcA';
    const tToken = '0xD80fEE48D045F6c65de8DB9bd2e6587648d50eeC';
    const tTokenB = '0x982E7cF704020042d6ec2b48Da32c6AEC2E24bA1';
    const WETH = '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91';
    const USDCe = '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4';
    const TUSDT = '0x5F871256D9178D30797a7685902A5A08370bF14c';
    const MUTE = '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42';
    const ZKSHIBA = '0x8d114dc0a8C40Ee9929B425Ab7E9afD4e61E4085';
    const ZKDOGE = '0xbFB4b5616044Eded03e5b1AD75141f0D9Cb1499b';
    const BNB = '0x7400793aAd94C8CA801aa036357d10F5Fd0ce08f';
    const SLP = '0xD0cE094412898760C2A5e37AbeC39b0E785b45aE';
    const TEST = '0xf6cBfd8890e38311291955B311e4479290Bd5Aaa';
    const WATERFALL = '0x0CD0Dba3d82057bF6B76746b968b2e1A7aDB08bF';
    const WATERFALL15 = '0x6fd32b143EFaB1153B37c661a06fBDb8F37C4659';
    const SPACE = '0x47260090cE5e83454d5f05A0AbbB2C953835f777';
    const ZOHM = '0xacE41ffA123a779428eDE67bf9b82eA2783572b1';
    const OT = '0xD0eA21ba66B67bE636De1EC4bd9696EB8C61e9AA';
    const DOF = '0xCFfeC2c683762831D16a9C5b9c70ba1789bbA1f3';
    const VC = '0x85D84c774CF8e9fF85342684b0E795Df72A24908';
    const WISP = '0xc8Ec5B0627C794de0e4ea5d97AD9A556B361d243';
    const BUG = '0x4110D15B1049c976133Dc335285a53b2D916393E';
    const Cheems = '0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E';
    const ZAT = '0x47EF4A5641992A72CFd57b9406c9D9cefEE8e0C4';
    const BUSD = '0x2039bb4116B4EFc145Ec4f0e2eA75012D6C0f181';
    const SPT = '0x1FFDf73362c78B74E96C194e7b102A9f80F12B47';
    const PGB = '0x28a578Ff71906CB24FBc3b69459676b95Ebb1c21';
    const USDT = '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C';
    const WBTC = '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011';
    const SIMPARB = '0x5312df841CceCCDDe51B30A580c671de48ED8a48';
    const PEPERA = '0x60143F39b54a731aC790708c8719211878e638C3';
    const ZKST = '0x5ceaFD95E5Cd1D9D02ffea1B66970e3902F32f1D';
    const ZKST2 = '0xc73B8Bd7E82125369944cf9C5C8481010d689F0F';
    const LUSD = '0x503234F203fC7Eb888EEC8513210612a43Cf6115';
    const SIS = '0xdd9f72afED3631a6C85b5369D84875e6c42f1827';
    const USDADD = '0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557';
    const DOGERA = '0xA59af353E423F54D47F2Ce5F85e3e265d95282Cd';
    const PEPE = '0xFD282F16a64c6D304aC05d1A58Da15bed0467c71';
    const DVF = '0xBbD1bA24d589C319C86519646817F2F153c9B716';
    const ERA = '0xeCD9f240ED3895c77dB676004328Dd1d246f33C9';
    const LSD = '0x458A2E32eAbc7626187E6b75f29D7030a5202bD4';
    const zkUSD = '0xfC7E56298657B002b3e656400E746b7212912757';
    const spfi = '0xb900692c82511e72939c9771B313c78548e8a22E';
    const spfi2 = '0xAB4d59450680E922295AdB40dc713006B36b70B5';
    const AVAX = '0x6A5279E99CA7786fb13F827Fc1Fb4F61684933d6';
    const SNRK = '0x533b5F887383196C6bc642f83338a69596465307';
    const ZPC = '0x4BE6f11Ee1310Ecf29A9d0130a12A9D0885e0c92';
    const zkSIMP = '0xE90A872286De0d682d18Afc7BDB124881AF5AF05';
    const MATIC = '0x28a487240e4D45CfF4A2980D334CC933B7483842';
    const Array = '0x6068AD384b4d330d4De77F47041885956c9F32a3';
    const rETH = '0x32Fd44bB869620C0EF993754c8a00Be67C464806';
    const DCA = '0x8b69A4e5AEB67858E3d03d0124176E85C3DFa51d';
    const ROME = '0x74565ED480460Ef465AfD9d427c624D5829cfc54';
    const VS = '0x5756A28E2aAe01F600FC2C01358395F5C1f8ad3A';
    const KIT = '0x1879a4a2730e3f5E3EdE70Ae300120816eF187B4';
    const CEUSDC = '0xA63Bb8d2a97912252212b2aFC5EAFc6Db7419Ddd';
    const fuck = '0xEA79962A23bbC62786C79e4b6b6213f04BeAb9c1';
    const zkAIRDROP = '0x538d4eD4f58c0C7DA7075e8bD4c61ae3461d63b7';
    const zkAIRDROP121 = '0x6A31fAA2523a2ecd65C7385333e359C719EC3E0e';
    const jgl = '0xc83Dac2d62a11adf037080D697d429e0aF72D05a';
    const pepe = '0xaAFcaAE639bF688B9670F239A66a89d55F9F7B43';
    const ckn = '0xA790c2237Dd5b8d95BdCA938CbfC38B5C70Ec8B4';
    const MAV = '0x787c09494Ec8Bcb24DcAf8659E7d5D69979eE508';
    const Mainnet_Token18 = '0x5D7D9C5020bA88903c1b696e248b5Bd72eFb3939';
    const vrf = '0xc4C8Ff83bDaB2A672dE15A75D83B344066D2B93e';
    const PEPE2 = '0x2A9E2aA58e494D898caE9Eede6c542a2Fb2B8eAb';
    const FRAX = '0xb4C1544cb4163f4C2ECa1aE9Ce999F63892d912A';
    const VZK = '0x995d032Bb8006d594794bedd529dE9CDe1dC3cD4';
    const ptls = '0x5cAddf39d5B037FE0f5bB82a81abeAAc7700323E';
    const tkzk = '0x476c094033D3644229A8f102F267E8D7423ff358';
    const CRV = '0xf3EE1000deAe67D94d397C2ce2c9D40Be5ED5F08';
    const zkSHIB = '0x48D6Bf6B70ea6595d3997f4E1725c455CE21396c';
    const PAY8 = '0xA49d55E0D8314c444DEC69eb9Ab397c60cEffFB7';
    const META = '0xB75890c3b9d02405F858A3f35Fb94d93B6021bda';
    const HODL = '0x16aBF7C6c3Bd6cff4d50A4e987Ca9552538d23fe';
    const TRYV = '0x596a8849B1c37D2dCb89879ad6E941BF5E40b1A5';
    const SCT = '0xd5cbA8ce99B9DCC903e3D5C66b49C5b5A50a7732';
    const VRTK = '0x96CC2557835EAAD746Ff732082aF73194a6Ff650';
    const BTRM = '0xc920681D1AC9576f787D51d2706C8E07409c3bD2';
    const KCAL = '0xbe9fe34705B8458c555Df07F95A3c44aD2efed07';
    const PIE = '0x69384BEff5B5c8c75eb3ef5C7CdEAC7ADad34eEF';
    const TAKEDA = '0x8BCba387f7A439AC61D0D7fd2F31b44460c54e4D';
    const CICC = '0xd99756Bf95E80a01094a6Bf1A963B07eA47aF415';
    const AUSDC = '0x253e65423692681012D613D5842A0a3097b69DF0';
    const MONKEX = '0xb79990366eba4dA6bd6Ad741f0ce7b9339F530B5';
    const BRS = '0x70bCF51995f5CB30E3ea07d5dDcCBF7064CB3405';
    const QUICK = '0xe8C09A3B1D116d034C23e4D682cAb1736887dd3c';
    const XMR = '0x77305941487591b2fB380cf693540c6bc48BaC14';
    const ARC = '0x2d7Fa7623A94b3863dB207ee4B8D80A411a46281';
    const DFL = '0xaA1bD443c7dAd4d48783Ad48BEB9896105D30b99';
    const HBG = '0x2Fca9A3C60Ed282fd057009cbD2E96181f7f954B';
    const FTI = '0x0A7E92969D610AAf0a16d6A5C1e0d6F7FFe50e1a';
    const MOONION = '0x73D4cd19194eE354fA0849201C35333657D21853';
    const HODL207 = '0x7dC2bD5c67e58048A72dc3ED79F52A08AEaBc115';
    const SHIBA = '0x67B82c843Edfd4b495CFD4521f80E2630eC0d05A';
    const VLTY = '0xCAb103ee1A5d527f90Aef2390c77cfC4aE2f0F7c';
    const MRCH = '0x8cC5a89A6FB6d2FEb6DCca7b1C7142484aa933b5';
    const UZUMAKI = '0x9a41f00d26679403cDF7DAa92c20404c39f93553';
    const PTX = '0xEb0B7Eb57F2735F058143c18E86e71088DE71C3C';
    const NST = '0x67Ad9bd576AE8C764a2cB74940D57b68b4cd574A';
    const GEC = '0xF891bE38f685f1485cd9e3CCC50587Ddd4fF0e4a';
    const C50 = '0xa32327235608CE36Bc9A5Cc4901c197226845DCF';
    const KAERI = '0xF6135a6F64f33a03B5b071E519C80D142a8dB263';
    const WBTC217 = '0x54C949615939d07b6796664814cc19329907F420';
    const ISR = '0x4753356E4dE6E612719C4D85Ee0B3865F31E7084';
    const JNS = '0x1CfC441c4789a6a0a924c38FEdbAFECfd40cd69E';
    const CVSHOT = '0xeb81dFd2f290b7e745fa409f94cfCABF700f1896';
    const ACE = '0x0215e39743d69BB11DD6BF12C5aB872cf5f852F6';
    const ABBC = '0xCe00d0beE4A7F39B202d967ac11AaF31c67d4bA0';
    const ZWZ = '0xba36bdd7F1Ad5023B50fC85660E7788EC145D4f1';
    const MNFT = '0xa71D6DF79e141b4a2BE26138e4eC4219d235f430';
    const MPS = '0x6742c717EdA4376e927f94f4974CeB45AE7A44cC';
    const OAX = '0x2Dcab275660d4E394E37d5569B460A7b2eEE5769';
    const USHI = '0xBAae6AC35028eD8565Fe8216608A2d3BC6A70a03';
    const MTSKS = '0x80286484d8eCBF56e63e25Ea3CD7360756c60A34';
    const FWW = '0xfd2342661011b0f7Db381b9622066be4C6bD884f';
    const DAE = '0xE0b78e93C164cd63949Fc7714CFd1C35F5D5b3D0';
    const PLY = '0xadB90E063e082777E7799F8ffa9C18bB4E037BC1';
    const KIBA = '0xCf0856FFf207E6D77b80451785Df02Cf50E9F389';
    const BONDLY = '0xC68420740f1cbb818B52b78261646bE42B7279Ea';
    const AHT = '0xC4DF35AB504613bb16D3c70d55CA658fC846978E';
    const VEN = '0x2A051E352eEf0eCd78eD50369eC7D1C5cdAb28aF';
    const OOE = '0xdA38cAE3893BA348cB0E0a3b5C20b80d387Ac83f';
    const DAM = '0x45f36f0b44e8B9b2086021d3546CBf2BA0E93B09';
    const AGX = '0x4857b95807Ac7Aeb8ccDAB4A134718eE5A3bc6c9';
    const SPACE239 = '0x06A04C3f4B0c8Ccc73c9eC63A0b55693755D8317';
    const EGT = '0xE57113D7cA2376aC72D4950fCD927E5d1b54c02C';
    const FYD = '0x7579FF3B67eaE810344bb3ffA2F55b48F3c2e915';
    const RPS = '0x2570C9B062c536b5097A38b3E85e3B7F677fc304';
    const CLINK = '0x5D37FF30681e32b7571FD653c7ce1235F4c4340B';
    const SOLBEAR = '0xb32B8A5800572E7e6738e4c4C2454212AB8eeeCf';
    const KOM = '0x954C532895d67e9da52e658f47ab6e6F5D87c894';
    const MNX = '0x0d796955547014Ac57C5403a18d0523E39b67ea3';
    const ADEL = '0x442Ba85BB22DE205310B8340323A3875C9bCCE59';
    const HDAO = '0xE86Fff16736DF31e3C070bF5B051984283e06408';
    const EGG = '0x75238560B7058eE7107613cb91546EA432D3981c';
    const INN = '0x7127b08c85a4CD32fcDf8522f24C4c8a84412588';
    const SPEED = '0x140c80b51db8a62631573B6C5A387133EE631F6e';
    const TANK = '0x72F587048Cf88bB94e28B2Dc7e50A0F9B43552De';
    const RNB = '0xba417701DF34c00A7dd4269c769249d1FaBaFe12';
    const BMC = '0x278f8a235f7cC6EEd9aEA0A65ef4BF1c01E4538C';
    const SHDW = '0xD0eD40119313dCDD71E286776d3d9B6094048c37';
    const BABYFLOKI = '0x240Adb1526fe029484343430Dc17b6cFEe430Aa4';
    const METAI = '0x3c7cca3DbB293ee5FE704E2F0f72215128397364';
    const TIKKY = '0x9c34D272893fE655826f90Ab205148f569b65695';
    const MEISHU = '0x4171d44b3cea01dF20f94Bd3ABfAD0368040e47F';
    const LGOLD = '0xc30b3bA019A11aA3A44Acd6457499cE994BAb589';
    const ZF = '0x31C2c031fDc9d33e974f327Ab0d9883Eae06cA4A';
    const OmegaMoonProjectAlpha100BuyNow = '0x897f46657B8BCB657bF0E8309e36bA0C29570406';
    const yTycheV2 = '0xEefba9E9407fAcbaD4c16639868c1e9b77Ceb8DA';
    const QB = '0x6Da10F9689DD32F5e8138214D3440EA1295b2e69';
    const WETH301 = '0xe3Ef4022AA3dfdbbb842C4cC554aDCBb4a3A8F0f';
    const GOLD = '0x40310A94130259F407DBD569142D2C2d4bF662aE';
    const ONEZ = '0x90059C32Eeeb1A2aa1351a58860d98855f3655aD';
    const iUSD = '0x1382628e018010035999A1FF330447a0751aa84f';
    const BEANY = '0xc65364c35D8E2aabA17D00E468c29576f3eC105c';
    const iZi = '0x16A9494e257703797D747540f01683952547EE5b';
    const yo = '0x39919638cf32f2ce301CBF4A5cF58E5B2190B0D3';
    const ZZ = '0x1ab721f531Cab4c87d536bE8B985EAFCE17f0184';
    const WEFI = '0x81E7186947fb59AAAAEb476a47daAc60680cbbaF';
    const cbETH = '0x75Af292c1c9a37b3EA2E6041168B4E48875b9ED5';
    const FUL = '0xe593853b4d603d5b8f21036Bb4AD0D1880097a6e';
    const GOVI = '0xD63eF5e9C628c8a0E8984CDfb7444AEE44B09044';
    const uGOLD = '0x10d967f46b06580C4a87b05c78f04e4Df25C0DB0';
    const ZKPEPE = '0x7D54a311D56957fa3c9a3e397CA9dC6061113ab3';
    const KAT = '0xCDb7D260c107499C80B4b748e8331c64595972a1';
    const HOLD = '0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2';
    const STAR = '0x323ABEce5Dbe3b0F34c3681fB71743c638FB3635';
    const STAR425 = '0x838A66F841DD5148475a8918db0732c239499a03';
    const TiTi = '0x4EBfb78C4780C304dff7de518db630b67e3F044b';
    const zk_TiUSD = '0xC059767cB62F003E863f9E7bD1fc813BEfF9693C';
    const USDC = '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4';
    const AAI = '0x144B83555D8A3119b0a69a7BC2F0a0388308FeE3';
    const ZK = '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E';

    var WETH0_tToken = '0xE03bECABcEbc5b67F61E8676D057ee0Ee4810b42';
    var WETH0_tTokenB = '0xc0b19422E63E0fD2C3Fc46C27D06921998001F16';
    var tTokenB_tToken = '0x8d3aDf0Bd56e12c043decdD8cCE53434809A4f88';
    var WETH_tToken = '0x9Ae019Ca495378340eE0Afa18797A9D8d6B9C966';
    var WETH_tTokenB = '0x510400419d7D91796d1231dd9A098aFC6C3e4D4A';
    var USDCe_WETH = '0xD0cE094412898760C2A5e37AbeC39b0E785b45aE';
    var WETH_TUSDT = '0xE3562D14FF1524D7dAFdef9e5DAe37eDD3719B9A';
    var MUTE_WETH = '0x554d965F4d918B8b93F1bFdea004e8E8585811bA';
    var WETH_ZKSHIBA = '0xbDf712b45b3B65cde0cA611e3ebAFe3D6f53d14f';
    var WETH_ZKDOGE = '0x2b5E1b8e6B37fB55e003B2C395F2Fc26BfDf0437';
    var WETH_BNB = '0x11af4D27c032c7b37e92585A5514fFc8f357f405';
    var WETH_SLP = '0x1fEb1f07f80c4036Fc1e0Bee911c04C103DB60F3';
    var WETH_TEST = '0xd7CC60C16a2D19E06F64deEB9e17Fde378075181';
    var MUTE_USDCe = '0xBC4D6379597a177920FA73F18Fc8EfD3A471d0a6';
    var WATERFALL_WETH = '0x8E898C28b7fa818BDc1C3cE0609e9Ff5Af64755c';
    var WETH_WATERFALL15 = '0xD15caf1fc9f1dc68c84b051fD44fbaF797A4aA33';
    var USDCe_WATERFALL15 = '0x1d371169220d1E6d3fBB5e0B43d4BA21Cba203AD';
    var USDCe_SPACE = '0x4B747B1f9DBAE8C55B007817553B49aCb360812F';
    var SPACE_WETH = '0xfDC4Ce439e672E81c6B86E6Cd45ffB0A7CcF9d44';
    var WETH_ZOHM = '0x4bB0c404D4c7Ad1a095eE86E57b4cbEAF1b78bFE';
    var WETH_OT = '0xA2E496CFE293d2F370Cabf5d0CC381F532924AeE';
    var USDCe_OT = '0x7d7Abb8568ab9B97f3E6Fd6774F4372be847235f';
    var WETH_DOF = '0xC8Af75Ad50289a4C2B781Dc63762965975151ce6';
    var WETH_VC = '0x1eD8a1A4C235E217851Aa9cf68AB15F11019e2FC';
    var USDCe_VC = '0x1400CfB76b77116Ee987876cE2c4817F989DDD7A';
    var WETH_WISP = '0xE574D567Eb5C4F6473bF2D3D0b074C9b397Fa45B';
    var USDCe_BUG = '0x61Bbb72c30Bc30bBa7d52B7698a09c922cF6ec4c';
    var WETH_Cheems = '0xF291F49ab8C614110849C29762693c9cB9c72CA0';
    var USDCe_Cheems = '0xC90B1d87967DC79E6C2E563931A49D725eA391C8';
    var USDCe_ZAT = '0x157Cb755FDae3080e8B89F95f798Ee3Ac0f57b91';
    var BUSD_WETH = '0xDd64e23f79021F695A48C29f2932d362113eA4D6';
    var SPT_WETH = '0xf82A129622e98fFe3BBa73Be2587F9F1A2861D9C';
    var SPT_SPACE = '0xa65ed2689ca68B2cF790145d95F62fDDB7eA5C81';
    var PGB_WETH = '0x7Dee0E040F458aE005fDcAC78C88f241E7F335b5';
    var BUSD_USDCe = '0x793e1dCfC175d9013e89590eB5670831C1DafED5';
    var USDCe_USDT = '0xd283A28619D25596995Cf23b13C304171b582B7C';
    var USDT_WETH = '0x6567A946D10a83d65eA7Caab3a2a22a1Fa48e6B6';
    var WETH_WBTC = '0xcb5dC30b7824A6378a5f5c2921e58809c5c0f5d5';
    var USDCe_WBTC = '0x3d0C29c27c8B9B3824853C2AeCF1CF9617734a0B';
    var SIMPARB_WETH = '0x604414624951B53aB38d9b477899399B60071b3F';
    var WETH_PEPERA = '0xFCe5cDD4bb0d0847B8E756E2965A5f35660A891b';
    var WETH_ZKST = '0xECf2E582eB78D43aD688e23B80c1732F956a383a';
    var WETH_ZKST2 = '0x282584f291f075D6F9caa178B52214423ae42226';
    var ZKST_ZKST2 = '0x0915d04b2D204027325f35Fd5d76E8515ce27AA3';
    var SPACE_OT = '0xb40Be163195eA8b1BECA5Ba88Bdafc14565de9C6';
    var USDCe_ZKDOGE = '0x8275D457a35aE12730F6D0E7D4879E231d4503e4';
    var ZAT_WETH = '0xE34e48d46B65A33D221446902ae44D7Cf8a60cCA';
    var SPACE_USDT = '0x673Bdd122Cf1cce3EA3E5F1FACC14ccABe9B3f95';
    var LUSD_WETH = '0x7c0876AdF74900b0FB4C99bF7495D2c22E7bFEa9';
    var WETH_SIS = '0xd4d060369eE552cf73EF34d230e1CFA5abD53eAA';
    var USDT_USDADD = '0x8D5bbc012aF1537BC285e640929211AE2C19FA01';
    var WETH_DOGERA = '0xA460d8538A861da0a0b3137A8D136a3E915ac6b0';
    var WETH_PEPE = '0x61Ab14F1De96703fB24B5211839259C602926445';
    var SPACE_DVF = '0x9b7C8f064BdFcF3462A38Ed6F402D84dbC701954';
    var MUTE_ZKDOGE = '0x35eA20E5c01bc4e3E1cf648895c9062951Fd1d52';
    var WETH_ERA = '0xc0BEdCdb43A023E2eF5B1173122C486f4B46f3E0';
    var LSD_WETH = '0xFA0b09f02666ae495Ea3ddcE0674CfF2c42536b9';
    var OT_SIS = '0xd8c5678674eB83c9a202ae3f3c3A4799c60ec739';
    var SPACE_zkUSD = '0xD0F6aC198881e93BBE5d0eB9A411860228D498A0';
    var WETH_spfi = '0xb66122d3365a29E16fE9015C92813DB85c04B87B';
    var WETH_spfi2 = '0x1656Dc5c3b01e738459c4C5Ceb925ea9e85c51FA';
    var spfi2_spfi = '0xcF3a564f864068e117ad9BD75A7368d079FA7c39';
    var SPACE_Cheems = '0xF07D9E6ed2eb731606F5E61105Bb0f7473eD1bB7';
    var WETH_AVAX = '0x7933d55b196cf20B6a32919cA8A237A0663BA84a';
    var WETH_USDADD = '0xaa5585b7E359dbC381Bc62f65c9b00F6A9099516';
    var SPACE_USDADD = '0x7726a9b481B35De9a937b84Ee6f2D6498a4caf1B';
    var USDCe_zkUSD = '0x93e7740BF271b7bc65401445f43e01C24b4b9c56';
    var SPACE_ERA = '0x0A9b33eA8e378e3F13E4236F7f135a094d9611D9';
    var USDCe_LUSD = '0x34552a0270Fbf4B43426659cF51Bf5624fE0A0A6';
    var SNRK_WETH = '0xd66bb2d54A2958687342473d94f729e4dA7f9bED';
    var USDT_SIS = '0x42807fAe4E2ba3f8C9b74E24ae34578B072bD5bf';
    var ZAT_zkUSD = '0xE664806E8cddD60EF82f26fA7171b4209208f8C6';
    var MUTE_SIS = '0x1791E0Cc03c50b91fFbd3D8f6cd7Be82a3896338';
    var USDCe_USDADD = '0xF17cb05b782D75407b6C54411A97D4e459D9110F';
    var BUSD_LUSD = '0xF72735fFEc5791Dbc3002Fb49f5a4588de734341';
    var ZPC_WETH = '0xf9D11ea637B431Ba70DbBC2FdF5C444d70ef0472';
    var USDCe_BNB = '0x738C6E3D6B08e5408a3c85D21eeC54251A3665D4';
    var USDT_OT = '0x6A2d6f80cBbAea73aa826C9DBe45ABAaB50C0ce3';
    var SPACE_ZAT = '0x2fCa3bA97BcF4Fd9eb53Ab2b4ffc6e5866F7Fb41';
    var WETH_zkSIMP = '0xB8C566c9208028297CFD87F9f7c272a257c38827';
    var WETH_DVF = '0x79BAef4Bd045035e4A23bFb42d8B4eC107199f27';
    var MATIC_zkUSD = '0x17Cb1fe6A22b76F4b2Dc01E9652caA88c3b3684d';
    var SPACE_LUSD = '0xdc4F36D9d152950c9F403bA288e7b7A3e154A1FB';
    var USDCe_PEPE = '0x3918f767eBE0d1825846988E904eD13A701397dF';
    var USDCe_Array = '0x73199326613fA474720D5D6E08BcC611b4abbB27';
    var WETH_zkUSD = '0xd02Cf7c19E722Fd520de0EFAdE2A24b85b35d250';
    var BUSD_USDT = '0xEC45Abd6382715920c83cdCadff06348D7B4f764';
    var SPACE_DOGERA = '0x8309AaC73E89a62D5A26a221a1Af3582Ac164bb3';
    var rETH_WETH = '0x8F8cb7260B825F45b9C921713D5Fe69a08a75456';
    var SPACE_DCA = '0x25042Cd9553495F18c6deFd3ef959e0DCCA57f36';
    var DOGERA_PEPE = '0xeE3913fDDe351BE6d5faCE44f3E30F0C6D8444dF';
    var ZAT_ERA = '0x6940c2a39738014450033F2D6627D031B539f6f5';
    var WETH_ROME = '0x61275242F05C14e42Ecae97eab478Ba4C93BfeEb';
    var BUSD_USDADD = '0x8c5d9C74dD52AC2CAf1A19F89CA1257AB8fa1654';
    var BUSD_SPACE = '0xe38FC8663b577CA9AeE050cd229A8C096779400B';
    var BUSD_OT = '0x948dA0e0Cf9406c046c6d16E971eF8885c3948a9';
    var LUSD_SIS = '0xa4e876E620642e2008b2c0c78d4dfdDEc200edC4';
    var ZAT_VC = '0xF37378363f0126dc58A4510Bc61ade2c8Ef2f2f8';
    var USDCe_ERA = '0x7362C55F4Ccf70c237E0bcC96e6862fFc0eF684F';
    var WETH_Array = '0xe0674459b5ff2487a5a89987Bbc799564502a635';
    var USDCe_AVAX = '0x572C8726062CC9d57f4aDbC16f20b6EdB05FDE48';
    var MUTE_SPACE = '0x7effefFd3b08205DC28f9bB47441CeEDAF3940a2';
    var BUSD_BNB = '0x70a60A6396e0D07A21130A174A46Cbb708751563';
    var USDCe_VS = '0x830F4307568DB7F057377E45cf86Afe61F663335';
    var USDT_LUSD = '0x9E33a5339c8E471536d0AC825c7401094ab9Be23';
    var KIT_WETH = '0x2d6d5044a78649d4d1f2FeC0aF05f48f29449A8c';
    var WETH_CEUSDC = '0x328D1e696023b17E925953590529873DC3cb0A28';
    var USDT_PEPE = '0x21298F37361d0442dfc6faFbDc226b23DE23af6C';
    var WETH_DCA = '0xB32Ffc95D4f9f70F75803D85Aa6a511D1a1DcB60';
    var MUTE_USDT = '0x30cC0B899579587f9E63CD91f72cbaf4Aa7e516E';
    var SPACE_WBTC = '0x11C8511df9C70f9909b830dDEf0B1f848e823746';
    var ZPC_SNRK = '0x82c3Fb9BF6197a11C44D818134FC0c37D25CE226';
    var MATIC_SPACE = '0xfa208e2ef8a53762d151BC9A9b12F0A7D1D00e9f';
    var SPACE_ZPC = '0xfDB536C2e07a94a7CE92149687AbfbE1ad3333a3';
    var BUSD_Cheems = '0x917E92723afF7f60c8E755b2aCe3382273BA7186';
    var LSD_SPACE = '0xB05F58Fa20Daf41102e73dEE2088a6f4798c3FD1';
    var MUTE_ZAT = '0x4C5dECEcCCbd558E1A417892B6A8c946d83F2247';
    var USDCe_fuck = '0x46044bD07c4e1b454103ad089d17fEF735C11269';
    var ZAT_DOGERA = '0x0A6Ec008f48a5c4878E0A283Fc02a1F3d6aCdBC5';
    var zkAIRDROP_WETH = '0x9DcCeef2F72e47578f17dB1967Bf53da0B758E9F';
    var zkAIRDROP_Array = '0x70F792481c99142bA6Beb16fE77b96CDbddF0c72';
    var WETH_zkAIRDROP121 = '0x52ba7AFC44fc41FAAc7F7fb0Deaa413bb61fB817';
    var BUSD_zkUSD = '0xCC283a6D1CA7194021A4FFc45920a6e072599927';
    var SPACE_SNRK = '0x2B308f0D0072E74260370C5D6C5f99Bf8A623B90';
    var VS_WETH = '0x1e2b7ad10Efa09bE68989Bc8F3B86012D3cCD5bC';
    var ZAT_jgl = '0x09213CfA099B8DD383B2B9F2809ce069cd587356';
    var BUSD_WBTC = '0x5578585276d4BC16A376fB0038f62197ee6A978A';
    var WETH_pepe = '0xa12d2759fbc4C74871016F6A93E0A191C56F487d';
    var MUTE_VC = '0xE5e0B5996D6fcfe067AA610F1736280f71bbc128';
    var ckn_zkUSD = '0x0202C8fF7cAFDF589113037749AfeE17E608a80d';
    var SPACE_MAV = '0xD43d3D3975980805234a85e7A8C41A4Ec779f9BD';
    var MUTE_OT = '0xc39a4aeE103a785ca30213ab910Ea6286D925164';
    var MATIC_USDCe = '0x6f204bCaFdb2b71842F7b8C5d8b464E1195f1636';
    var LUSD_zkUSD = '0x73254f09f14fF14AB731Da21aB20Ea3f11164DF5';
    var USDCe_DOGERA = '0xb388e4fb648DE84A90209CF035a06e57c5fe8bE3';
    var SPACE_VC = '0x67B9EcefF7942Be24B34861E345e98d2A37b238a';
    var WETH_Mainnet_Token18 = '0x34E9d27EeA1AF86f3241acCec61d1f58db233C95';
    var WETH_vrf = '0xCe8b7e8e1e40fB476B7d7DE9Ff27Bfb5bAd1Eda3';
    var MUTE_AVAX = '0x17F2d39969dB7D107747E45ffDdad36d0f95EBAB';
    var PEPE2_WETH = '0x9c415132eD33af8986F3619226dcAD74ad90e93c';
    var BUSD_MATIC = '0x3077bf4328d0439e6dd5649758978DaAcC47029F';
    var LUSD_USDADD = '0xe5104DdC5Cd2330d2207A8890eC3903B4aA57d04';
    var DOGERA_ERA = '0xdA4dbe583B794FBe653f7776B2f415c72B0819C1';
    var SPACE_ZKDOGE = '0xec4Ab7d32537dF18e10cA91270EBB02943f603bE';
    var USDT_ERA = '0xD78BdBa0942548f03F7786dBD37884E1C65275B7';
    var USDT_zkUSD = '0x72ffb24396586f91d9D808485C822fB46E02e138';
    var FRAX_zkUSD = '0x74c76503d5628638E2371C8390DB7F51CfD97742';
    var MUTE_BUSD = '0x2a5653a586f158EBf6bC84bFa1f54F68B286E1a0';
    var ZAT_Cheems = '0xf5f0B57527b56303E3C8B3882F89EedF489988b6';
    var ZPC_VC = '0x016b1B18bb90125146ee2B2E0e35403563cC3dB0';
    var WETH_VZK = '0x4a008A3bAE37f18cF27c98E473009048E2F123e5';
    var MATIC_AVAX = '0xc1322d263C7a3736161aD57B37DA055b03a35A3D';
    var AVAX_BNB = '0x97f4478c190e21B52eA6155c98BfCDF918e6b1D5';
    var Cheems_PEPE = '0x7c099014224d893CdF932Fe480B2fC9B69be8930';
    var MATIC_WETH = '0xF2d0D8D6e73595051e6847E457bc29cEfB7f0c28';
    var rETH_Cheems = '0x8Be860EEA23dE0AD0dE29b12d218C0308F6E2Fb9';
    var SNRK_DCA = '0x2C35568F203CCB70a89F31Cd23adaE1080A8e035';
    var SPACE_Array = '0x82B12Ef4345670D48E52D709aF9FB8b10f3c0Ced';
    var BNB_ERA = '0x8F320Aa106a3ae73BFdDF7c35E840F5a9E232Fd3';
    var Array_AVAX = '0x506404c756Ad3EA729AcaDf78903BFc6ea0Cb351';
    var LSD_BNB = '0x8275B9EdA44FbAD36B53b3c4BcB1a6D24ea173ba';
    var BUSD_jgl = '0x99b29Ebe99adC81C70FFaCFbB1a449DaE016673E';
    var LUSD_Cheems = '0xbDb88350eb0b357EAab7ad5E98B0b756Fdf8CF21';
    var USDCe_ZPC = '0x0c75f960057979dbcb200958a2dCDbAEb738cd24';
    var USDCe_DCA = '0x4B69448EA6B7173A4948331Eaa8DCD1DB3DFf23E';
    var WETH_ptls = '0x848EA01d10f6A9cA78371cd2Ab5438D5c4847603';
    var tkzk_WETH = '0x2aCadE43c4c0288034BC56512A443e44a66363E1';
    var Cheems_SIS = '0xa230275Bb92ec2D6e7d929353137c550AFcD9230';
    var USDCe_SIS = '0x1c9f7E5Cb6Be6F2f4F5c45258e891fB3361D8D8E';
    var BUSD_PEPE = '0x2e15e7836936dfBAE02deA007ed270310C91cb70';
    var PEPERA_AVAX = '0xF27bEBe06132bADE7529Be8F80988DE0054EB843';
    var MATIC_USDT = '0xb153278dbA133A4Ef6b0E6Cc8b87f5a888354A8c';
    var PEPERA_DCA = '0x2f48fb4051E6EE2D62694F1Dfe297f18350A0fea';
    var WETH_CRV = '0x78807AdDd5496E2C6AdB08cDC9Ce68d27B9d1457';
    var zkSHIB_WETH = '0x1608002b7702632C77c31c95d49ec2A085A3B2ee';
    var LSD_ERA = '0xa43D2D34b790f7118E91aE83343Dd2adE193c629';
    var VC_PEPE = '0x72bDE3124Ca41177F828f318B203AA194C94D8fD';
    var OT_Cheems = '0xc95b7018f09e2DFe78593275741CebAb72bf9Df4';
    var VS_Cheems = '0xAA70d7395944A5c0E8B0550940245f63F7f21004';
    var Array_OT = '0x5A938C78025fE5E8cFAB7C71dc0b0c3e635547ba';
    var ZAT_USDT = '0x564D5EBB5d7EC2Dab3f8F12db24860FBCb3d1035';
    var USDCe_FRAX = '0xaa37dEdEabB80C2015d22CDD7294092eD4F0F2c9';
    var USDT_FRAX = '0x85f424713AA0F90E772124F14c1b6caA27378ca5';
    var WBTC_PEPE = '0x241fb8ba3D3FDEf5E20738c611675e672761Cc15';
    var Array_Cheems = '0x02B6cF34D7889a6858261C4CDA35120f63cC4a65';
    var BUSD_AVAX = '0x2b887635EAfeE52641a06A0F0BD53D7226e1A479';
    var WETH_PAY8 = '0x3bb16101fAd20321dE9A2d23418A150084B1B7Cb';
    var WETH_META = '0x0e84e05373079a19E3b7beE9f71A77D23eF15a01';
    var HODL_WETH = '0x5Fc2aaD88015f974E12b9084082FD383Cf27c082';
    var TRYV_WETH = '0xeBcb9382855A7c540D5a5d4049B931D28b71C007';
    var WETH_SCT = '0xfFc8dEd643cF80cc3c2Fd0d41250fc6Ea4Ce9050';
    var WETH_VRTK = '0xAD343805183dd112F32e06b3DdF7f8814757151a';
    var WETH_BTRM = '0xC1c3267cb4F7df8F37f7410f69E5465d721B0c1C';
    var WETH_KCAL = '0x17301cD52140dBc4Ce389a2747718CA31D6D576e';
    var WETH_PIE = '0x2C0FE8A3943096A590FCa5ceEa13B08223922633';
    var WETH_TAKEDA = '0xae8a791971b8c058aD5662FFE5f7a8D9569cd05D';
    var WETH_CICC = '0x7d6Dc6770a4815C8C12B16DE86Ab694a14AEf1b9';
    var AUSDC_WETH = '0x4AEc375080fb10EC8a710F673755a6AD8C755674';
    var WETH_MONKEX = '0xb69F43f0c3DDE43A0CDd89933FE8a2e91489eea6';
    var WETH_BRS = '0xA8108c04157C854199C522aaFF8BC71BBfFd0117';
    var WETH_QUICK = '0xf9Ed7dfD965c760Bd9A3E4eBd0D15Ba5a5500caf';
    var WETH_XMR = '0xeB5F560264B11A45C7f639aa9037171f75262A71';
    var ARC_WETH = '0x50Af368834ac6E826d10BD3ff2817C7b28fD2EE8';
    var WETH_DFL = '0x10320a9529239dBa014e816FEf4e7eE9eD3F0E29';
    var HBG_WETH = '0xd916745798530F8A7d3A8733ef115d41D288c9d4';
    var FTI_WETH = '0x0CD8a6751CA9968bfEA469c66e5Edad4CCb9B55E';
    var WETH_MOONION = '0x4b6eEB060fE432D55882Def7F342Fb27CB0A0CfF';
    var WETH_HODL207 = '0x4CF242782031E7c15CfD53cccDAe67E4B91cba7a';
    var WETH_SHIBA = '0x954015F799cD4874f45c74225B1a0AeF41aEaa8B';
    var WETH_VLTY = '0x701DeECfBd7cB202695956A7D625b50A37E4a2C2';
    var WETH_MRCH = '0xbCBC03E242CfDEd3713267159A8EE7317c1bff55';
    var WETH_UZUMAKI = '0x87a47B4Ad944be2B307d42492d1B40B40EB3C4BD';
    var WETH_PTX = '0x30A5bD4E9E8019Eb1dC80413178158AA8f85034B';
    var WETH_NST = '0x7EaA37aE38CBF3883D08c1481e5d037060107974';
    var WETH_GEC = '0xbbb6CbdF89dc9Da32934770256324B771714096D';
    var WETH_C50 = '0x4f53257aF5Cf787Dd9C6494468fd624Ce14413d9';
    var WETH_KAERI = '0xF87753E2c68E524b09a2a434393f2bfce62E812a';
    var WBTC217_WETH = '0x719298204d1767C137fd1D70fDb8A17c7BfBa10d';
    var ISR_WETH = '0x73BE87535070C904d0f3FaDd7571cC68C76C223c';
    var JNS_WETH = '0x2c53Fc0D5Eb82d32Ed0951D07b246214eF109689';
    var WETH_CVSHOT = '0xc2e92A68873Bb33D38592cF787776C4b455871e1';
    var ACE_WETH = '0x1220849161ef9b0d314B4c40aC37911d3A7656DE';
    var WETH_ABBC = '0x8B562001e86849BAbb82D56647f763a4578E1C16';
    var WETH_ZWZ = '0xb42735e8de538E84c48603aC70dE258Ce5F97bFb';
    var WETH_MNFT = '0x28E10B97b215B90f2E23611bACa5810f6BE27b44';
    var WETH_MPS = '0xFD0C17a2f9417336F662c9Bc95D15E362652094b';
    var OAX_WETH = '0x247f57dD4e213Ded6f568f8251185D9b08066495';
    var WETH_USHI = '0x467CAfC39994caC3Ff497EEd28cc704b1702bb88';
    var WETH_MTSKS = '0x47EF15f7ea5eD7d53e836B501c35aA470d5b2020';
    var WETH_FWW = '0x7355dEf15eBFE37f70Be91C0A324C0c288DBB79a';
    var WETH_DAE = '0x91B9429D78e17db6E4C3960c1a3dEE1e468e9Dcf';
    var WETH_PLY = '0xf7aA4A5dD3A8d72ADe6589A58830e778fadf9a87';
    var WETH_KIBA = '0xB769752b5a635A24f458473F858f5A63245eBc80';
    var WETH_BONDLY = '0x163B0d59d70edeeAA17Ca93B14Ff9bD7181C5d1d';
    var WETH_AHT = '0x9f8308944B3CceCe7662105899361CD7650A5330';
    var VEN_WETH = '0x27315FDE1ED368a18302D9bD067781CE34e3Be41';
    var WETH_OOE = '0xBC8E163687696B795EFBb775EE9eEC5127F9848a';
    var DAM_WETH = '0xE13AFCc91A61cBe81278671c0E46aeEba0dBD7d0';
    var AGX_WETH = '0x47EA7e34d6705CA88D24a92756c8462C879dA663';
    var SPACE239_WETH = '0x39ba253BD88ccD9197639C15f318727a26F0eaFA';
    var WETH_EGT = '0x218A5e744716Bd835E42d347c65Fc2BE7B8826a4';
    var WETH_FYD = '0xA6AD650c086f6efaa661441E6C6f6b75633a26F6';
    var RPS_WETH = '0x583ff35fb6983A0D0AD90da7997467265D2B8E94';
    var WETH_CLINK = '0xCf6015F3846b4F39F0a256c1D1d13c8712D4EF90';
    var WETH_SOLBEAR = '0x1790728e5FDf8F659Cab123cC72f204Bb679C299';
    var WETH_KOM = '0x571D50fD0cC9f0812bcCb69B0e3726045AcB1659';
    var MNX_WETH = '0xe3dD561215A375d9B7222FAE0777ca7dDf777b8E';
    var ADEL_WETH = '0x9c5A11EF87ad538274F896a759449C5a7B22918B';
    var WETH_HDAO = '0x342D90C6AF308C2a31212E793f6f09bc04525493';
    var WETH_EGG = '0xC30B244E47eECc532eF47830a8894a55DE1cCfFc';
    var WETH_INN = '0xEBbB71b3a890Ea21Cf2a68241040ACF5F6BcE0d5';
    var SPEED_WETH = '0x16f28c445D28432893f36afDD1600CB7bF1b62b9';
    var WETH_TANK = '0xD4E026799aD7eCCBFf76B2F24D979c4a8B057a39';
    var WETH_RNB = '0xEBdf76A01C50143407601aF6feEF48E52a5890b5';
    var BMC_WETH = '0x0d72BEa4F55E7a2c28c79D6051a9C2D655C6eF3e';
    var WETH_SHDW = '0xE10ECceb6F1E221B516C59c5464861465aB1B3c5';
    var BABYFLOKI_WETH = '0x9Ed333B1aE006257F84E92885539D923748b6A47';
    var METAI_WETH = '0xE36b2f9e0823fBD26e72887E9e1FCb4D8547a124';
    var WETH_TIKKY = '0x4bd9F2abECDFaEe3676D1Fe93d8Dcd9A1147d22A';
    var MEISHU_WETH = '0x1429Ab111A85CBd6438b5d89eC33E032ad31D0C3';
    var WETH_LGOLD = '0xA6F1Ede6910dCB372990A6f0a5378BBA1Bc97C9D';
    var rETH_USDT = '0xD1656FDc462E973d536C8e87BDd0B99A2219d38B';
    var VC_OT = '0x9cf9fcac43Ea510FEd3D1aFB0B3Bd513249cd3CC';
    var BUSD_VS = '0xedcb4E512B5fCe713cdc9eA6057EC825f3DB730a';
    var VS_OT = '0xA7A1dA6FFf93d43e34BEb7C5777945A753945FaA';
    var ZAT_ZPC = '0xDd2542253Ca0587C4f4672523265Ae7642831d01';
    var Cheems_zkUSD = '0x3C97f46dD5Ef58b76171a97Df13391CD2d9F6873';
    var BUSD_Array = '0xf4a39e85F4A81a5b336658621a279308623Af029';
    var SPACE_AVAX = '0x35990fa335eCb93145b7fab77e4e055C461b5ED7';
    var USDT_Array = '0x97e2b1edE353c8fBf3cF316C4ecad8d68daC9735';
    var USDT_Cheems = '0x7dC29262D69ae822b3Dc1139B5A275aDf5f7f628';
    var USDT_WBTC = '0xA2fD612a9e4dCEe186B7cF35d005CfEfDbF32296';
    var USDT_ZPC = '0xdb0E0CE005095748ff2318406906481809eF0d9f';
    var USDCe_DVF = '0x8b0AbCb40b0D4042Ab9608ACC192740853Abbd3B';
    var SPACE_PEPE = '0x7C536110ec137aE5A331FfCF9dB810EF33681581';
    var DVF_zkUSD = '0x1175F63c97a9Bbc48952C704d4d7fDd9867162Ae';
    var BNB_Cheems = '0xB55dD417cabD7342AD505f980689Ab6712e06825';
    var MUTE_MATIC = '0x1d3560dAAd51A09A2d7a65087ae0De4a85005BDb';
    var DCA_Cheems = '0x8Fd10318103223a8bb9a7cc2B42Ab58285788219';
    var ZF_WETH = '0x8cB01D6b643d99252Ffe87D6Dc37EdC833b12230';
    var USDCe_LSD = '0xA0a8D3b6ddE5A22307c6276701a88dE623Ad45F1';
    var USDT_BNB = '0x1e0a1756907fae2596C24ae28537E3Ba86B7dD3E';
    var USDCe_SNRK = '0x8d68131a8A3c47aE980FB5894a78E7E5933827Dc';
    var SPACE_SIS = '0x5469601f4A5501A535130fDd761BF0fE5228c4Bf';
    var VS_AVAX = '0x375263fE949D6B98Fa28e40a0e960deC3F71d3c3';
    var ZAT_PEPERA = '0xFE528503BFe339E8c61426958BEAbEdC64126fE9';
    var PEPERA_BNB = '0x015A97A215Eb2103720201edA2D23CbEEeD44215';
    var Array_ERA = '0x4bD5605e0881C5d91638f32294d347E271D143af';
    var WETH_OmegaMoonProjectAlpha100BuyNow = '0xc25DD22783Fca5A91c103CA61D11b54Ff6Ce4A3B';
    var WETH_FRAX = '0x5864777Fe56fcd7823D79B0ea6eb22327a9a792C';
    var VS_WBTC = '0xFe32d9A4Eee6DC8D6c6f0369b8373f518Eb97825';
    var WETH_yTycheV2 = '0xB247774088C33Ceb9C408EbeE68c5F10f52eC8cD';
    var LUSD_ZKDOGE = '0x1390cD42deee0cfF0304f57cC857AFC54530Bc4b';
    var SPACE_BNB = '0x77D18155040f00667D444d339Fb3eAFAb22DaF98';
    var WETH_QB = '0xCfA52E852D853c18246A61E927D4D4C187D51586';
    var LUSD_WBTC = '0xA1702fc3e2801514572AE8b5D2e9726E9d064C18';
    var DOGERA_Cheems = '0x0B6f8DC97b7eAF21c80c922cB230630EaA05F3fB';
    var DVF_Cheems = '0x1C016E8235CD508EeB9a8d29a36a54EfB83bE61D';
    var VC_ZKDOGE = '0x8C80CAb739FD7A1b06f4011e4D8D9a3374FF81aF';
    var SPACE_FRAX = '0xCc86f65303c42DF0A7809c28BF9a87315164b237';
    var WBTC_zkUSD = '0x2AbC5Fc12E3471B930221C1CD9D5BfbeBaB915fF';
    var WETH_WETH301 = '0x403aC49fD9FD04b51D97A3842c1F03E0C4BFeA66';
    var ZAT_SIS = '0x6207F4464Ef747acB17Da530031Ac9168642429B';
    var SNRK_PEPERA = '0x549EFf096C41DBba7B3B89386cEA54eB4eCdD2e8';
    var MUTE_Cheems = '0xE9601821E836967beCec91817dA23458ac4A1235';
    var ERA_PEPE = '0x70cce16181e3f806af023a16255Eea4e8242Ce38';
    var ZPC_DOGERA = '0x398f18Db4F012C15a18FF3720ECb1107E4279b71';
    var USDADD_ZKDOGE = '0xd1C00599a678c7cB797557c72421957A95abAcA7';
    var MATIC_Array = '0xE2e60136498702EBd36B945D19928Bfb794E63B7';
    var USDCe_PEPERA = '0xd4bb4A92cc4371920D33Bfc2e33d8C4b7E781630';
    var USDT_DOGERA = '0x96EA1bf2959e22b72e9445a9Ac9Bb1B27F1d611B';
    var AVAX_OT = '0xF693102E7AaEd132873E0b612DAc481Ab008bCf6';
    var GOLD_WETH = '0x44d0496E5ce374C2092b874122231d05D67BaFE8';
    var DCA_SIS = '0xb1a42c9710c6B805E448A60fd8c5A45465E9Fc9e';
    var MUTE_WBTC = '0x23b1dBC5336c7669451e1920f2A2bAF23F5d2AfF';
    var BUSD_ZAT = '0x0c721D0adA214D4d0DB3f2Aa6F736FF62B7D21a5';
    var Array_DCA = '0xc1AC24CAd6A8E3B8180526D7183282739b010433';
    var ZKDOGE_Cheems = '0x0C4d72bb6c233155459d11d833C86b2B71139354';
    var AVAX_SIS = '0xe16d0EcF33756B1341969fbec61A79c841888538';
    var USDCe_ONEZ = '0x48a7A10EE6a9De6CAE152be70DF7ff166Fba70e6';
    var iUSD_SPACE = '0x624d3A6290cfDCFA386bc874a439725c0036c9f4';
    var USDT_AVAX = '0xD3A1717481c7C3f4ba6E6fb547b6be446490AAFa';
    var ZAT_USDADD = '0xc6443891Bc2a27Af85f03dDAB846f5C09A524c9F';
    var BUSD_VC = '0x417eA2CdC7FEbFf785Efd5B86A3802468138ADbE';
    var USDT_VC = '0x568ada10b7B7C5027e2A2aE45E8554f5DE74256A';
    var rETH_WBTC = '0xf012D774F2AC8f629ac059Ab0670c61d9dcFdb69';
    var WETH_BEANY = '0x90eE441e8E82816A7eC3d693D69C3d157fdc854E';
    var ZAT_BNB = '0x19bFB545616525f5a7E961b4bC483D8479c22605';
    var MUTE_Array = '0x8e36BaBc0D8EBDeB88C1f8E0989BC3261ad0c763';
    var USDCe_MAV = '0xa151dfe0b690EDf69c7E44A90eC4e3f9317dE191';
    var MAV_WBTC = '0x7e4e870B7af5084A7DedE4946e20eFc0F2bAC57b';
    var iZi_USDCe = '0x4F08C7241e9c3e072FFA05Fc6c37DAff0288593F';
    var MUTE_zkUSD = '0xb143DB6Dc7E0fbf59ad2fDa9b680ca09f74D6CB8';
    var WETH_ONEZ = '0x95a9c3f85cE15C1f6b7190e4282a636C5bb892bb';
    var iZi_WETH = '0x5768D532F74B6333903ac129394E1E2e67E16b63';
    var WETH_MAV = '0xaa25154B0B5d66De0a4B427796af81f21e9CB022';
    var iZi_SPACE = '0x9645F7651393Edd51C43026A80f054973E110917';
    var yo_WETH = '0xd187b47f990663549E4ffF83d0AC7A39e9bc32d7';
    var VC_Cheems = '0xd4A71012abE6ffD88680339b596ed6DCd2bbD22e';
    var rETH_LUSD = '0x3e2C41Ba84336E3fF90cFdE5059eDd36463d8104';
    var MUTE_MAV = '0xb71aeF8c9B9471A11bda85550e6e73d2Bad49E23';
    var USDT_MAV = '0x79DEa36829d5342Db18a3FaEA663458f909d0aEB';
    var ZZ_WETH = '0xFC567C9b29B268c248d785C580bd8Ef382A44f9c';
    var WETH_WEFI = '0x70001CC30A0f7bEDc0d5CB86a5144374623A9865';
    var USDT_cbETH = '0x087687C4B50d185a0EAF1Bf58BbCA2E116c1E6C1';
    var BNB_OT = '0x13f21b28033373F3B88c6698eE65Ab023AEF17B3';
    var rETH_USDCe = '0x99A1405D86892d69B670235377841EBcB260731A';
    var MAV_DOGERA = '0x89515420cA0875894b342e0d9FFC984658e3c0e8';
    var USDT_ZKDOGE = '0xb7D0D41ac9E79763e56587F646254b8bf388a39F';
    var ZAT_Array = '0xf7492e8B8751548450C3c86f378818eE87d25Cb6';
    var ZAT_DVF = '0xc2CF4bC7f853983b3875057d613ED157b08f1751';
    var ZAT_BEANY = '0xB5681eC92F0cc21D81D8f5360c530328935e3067';
    var BNB_MAV = '0x9fe570EDB4FD42767cF9F0aC8C213b64EC540944';
    var ZAT_MAV = '0x5D1333a16Cd3ecA30F6FDf9A755cBD41d42863E9';
    var MATIC_ZKDOGE = '0xA920bAbd5075701D5986858079aCc62dAbD65f8a';
    var iZi_VC = '0x0B2EeB6d9FEA75C4aD76f647553FD46888A3Bf7E';
    var LUSD_VC = '0xC615F70AA0aaF27Ec10fF63C7A214ABE10C94c26';
    var LUSD_MAV = '0x1a0e536051105ffA372a2eE825f01E8eFB3527c6';
    var VC_SIS = '0x97daba0b9336692c1BEe95D137A299286F6Dae3C';
    var iZi_SIS = '0x33efD31d098Cc7645B841b2d438815b91E38408b';
    var WETH_FUL = '0x6B38c4DA66713Cd682ba02945C55cc10998c8e20';
    var USDT_PEPERA = '0x8FC2939d533854B10c699B88b04dD21b6216E8F0';
    var USDADD_WBTC = '0x49614f848CB879aa6938A36d5642b425A0B31BD4';
    var AVAX_VC = '0x518c76E1d10899Ee53125022f403e7Ab8978aF84';
    var ZAT_ZKDOGE = '0xDFFF94C8C001719E339F4573Cd7A5D48A7bc71BF';
    var MUTE_VS = '0xDf57e3A1BfeBbFcc195807C95D805414c68350ce';
    var ONEZ_DVF = '0x3c876c56ac3cd6d78F7EBC7bfebc03Ec4b48c39B';
    var ZPC_Cheems = '0x239e53D87c9701Ba74704A3bC4ea3B76CA8d6f06';
    var USDADD_zkUSD = '0xd73548a2d22Cf0fD0808667b983a922170355aFd';
    var WBTC_ZKDOGE = '0x64010E36A50FC96ca04b3e70b01Dc265c0c0baab';
    var BUSD_MAV = '0x0Ec41daD50c66Bf37Cc5505A7FC921413B32B8b4';
    var MAV_ZKDOGE = '0x546d71c4FF0F77Df6981B4e27fcC1403DCe598fA';
    var ZAT_WBTC = '0xCdD094056d73485b524628E170c44dbac119dE45';
    var iZi_Array = '0x457ea4921266c21802d32524DBc4772F0f00fFc5';
    var GOVI_zkUSD = '0xcC246200B1c02481Bb30A3F3e5e246f65cdb9F0b';
    var USDT_DVF = '0xd18b07f8F4aAf31c8B82596a86d7D4D69F56d4B8';
    var ZAT_OT = '0xC357e678C1f317f3D9F6847E98290B1DC2FD04F8';
    var OT_zkUSD = '0x55aAd8B5B3881EF2ef2F3Bdb5EA07e8442187bFf';
    var MATIC_PEPE = '0x5E02dB615E1a5f960239288FFD8a22191F848CcD';
    var iZi_USDT = '0xA233E05393F18CFb592485B13FAfCdc2449dF062';
    var ZZ_ZAT = '0x73B08C3Bcb34eF2dfb53c369De750611cEEf00Ad';
    var Array_PEPE = '0x30d4AFA53a45F4682c92359C2A24034D9E9558A4';
    var MATIC_VC = '0x869Cf42c7F3Fa0620070300C7f313140f75Ea8a2';
    var uGOLD_Cheems = '0x26887ad32e8c0C94295254c614063d4bF643111F';
    var USDT_GOVI = '0x42Bd449F221e0884e0b770Cf2c87724935EE6A80';
    var iZi_BUSD = '0xF5c1d7A16eF2F68bf2a797B6BF63e1437462b6b5';
    var BUSD_rETH = '0x24DC1C764d3bac0C0beC27792Ab78D3214FEBbB2';
    var iZi_LUSD = '0xdbE1faE6fc532cc77da154D51073b48957Fa0570';
    var iZi_ZAT = '0x91584d1b4401fAd3267b026F1e64332A06851bAB';
    var rETH_ZKDOGE = '0x21E4B27045a1Fba3ed159Bcdf25571639b185BB7';
    var VS_VC = '0xf14aEAEC3D0b3901c5d3EC04029B805406ADbE58';
    var VS_MAV = '0x5D1f6F75bdEFddc89126d6Bc7DcF544671c6e4C6';
    var DOGERA_OT = '0x343407c693638f1767AC7Ec61445BB43dFb89e31';
    var iZi_ZKDOGE = '0x0a2aA192f5023f36d5FFDfA0443b594261a6A2d9';
    var iZi_WBTC = '0x43AC81D1f23Db6cD06C358fd412562a222c3b1d7';
    var iZi_BNB = '0x0F39960f3d98EeD7f386cFBc66351395Fc8a4C81';
    var ZPC_BEANY = '0x147e351fDC449758713aeC8ca1a90158ea517582';
    var WETH_ZKPEPE = '0x3a35F37039385F95F5e85958018e5E6090c40B33';
    var USDCe_GOVI = '0xfCA06012C1d7B9BB57D821a88cc35dD86273f5DC';
    var SNRK_OT = '0x5ccc721c51E8f5941eea11542FA1DCb898163E2a';
    var MAV_VC = '0xE18703D8f33A8B66420dC265E67b418b431D7851';
    var WETH_KAT = '0xC4B0132915ED820A882269DD3D2b16EBAc3D9393';
    var WETH_HOLD = '0x2780F4f314d57558FddB4441d609C09773B8fBc3';
    var USDCe_cbETH = '0x6AD1D2E2fDBa71bcc3d9Db51b74c94f91482653C';
    var SPACE_KAT = '0xb6c295B3a0d582EF655f2CBC19D130273FF58983';
    var WETH_GOVI = '0x42353DC2e32a9204eD0D8fb97f2fF953516E0317';
    var ZZ_USDT = '0x72992df4B9C1251d4c8E7540164b6B3447b6aB03';
    var USDT_HOLD = '0xD44566FddF8544f89be52B1B95Ce6BaAce5Be702';
    var USDT_VS = '0x17d8C9E7A8949D04E1a5376F9caDCD0FceD2869C';
    var LSD_Cheems = '0xFA8E4bd3C611E98EF271F39599043Ce6CDB36e32';
    var USDCe_BEANY = '0x181c2Aec27B56A1715A961C43111A1fc91Fc09ee';
    var SPACE_FUL = '0x57a2240F6e6858A14a86226A757d623DE171B6A0';
    var LSD_zkUSD = '0xC5e7469FdD200aD69eb2A0282d732F3ccFEb6F2C';
    var USDCe_HOLD = '0xD14fCd6250aF241a18eD6817bb5A46f562A2701B';
    var iZi_ERA = '0xCe96ca3e88E0Eb062d40000c9C98777B219a333E';
    var Array_SIS = '0x06941Fe1c55A81682ed01F9b1b861c5C1A68D2e0';
    var BNB_PEPE = '0x2017a2475624B8826E76B60Af7510D0297EFB16E';
    var BUSD_GOVI = '0xFcF5Fdf49c056f312Be83553B066Cf9ca6Eb75E1';
    var MUTE_USDADD = '0x209c18d141f596fb4a2912b9258225547Aa8FE94';
    var BNB_ZKDOGE = '0x973134FbB49c48C155DCfF9c8af0F11CE32f2036';
    var STAR_USDCe = '0x0d33e5619609c6263955da788d90b98e308C62Fe';
    var STAR_WETH = '0x41bfB28445888F254b9ab5113f4C950cD0900cED';
    var MATIC_USDADD = '0x7571e6C43A68aaB6D6b377349432ea03071AD182';
    var iZi_DOGERA = '0x2A5B5D7BB8e24a4AF2DEbF25Fb0199ca0E3e1F70';
    var USDADD_Cheems = '0x011C2AF23d61aC73416c6DBDe077D39c6Ff4cE3A';
    var USDCe_STAR425 = '0xDB23688Aa1285DE1533FdAA8EbCc009904C36B8D';
    var WETH_STAR425 = '0xca623224d69b55c6A421245115c854C61147fa54';
    var MATIC_FRAX = '0x03025A395aE06eb6Ddd47Cdc13DA20BeCC61cc7c';
    var TiTi_zkUSD = '0xb172e927E65BC33585F9D8A3c2DAC9F4a38371a0';
    var OT_GOVI = '0xA3E5C92F1bFC78dfdf9b13c5B4D0955700705C6A';
    var USDCe_KAT = '0xBc2177C88A2B4378B954e89E2d37FF7647073DaB';
    var WETH_cbETH = '0xF25ac251Ed8D0E1F9AA18cD39b2551cB1391c2b0';
    var rETH_PEPERA = '0x30512E4B2FC63B3751547CCE4d8Cc149aC1aa5A6';
    var BNB_FRAX = '0xd2eAF4664784e9B7666c78e7cebbc05F25db0997';
    var WETH_zk_TiUSD = '0x76217AFa879B637810fa73b7B1830FfF8e0AaA62';
    var SPACE_STAR425 = '0x3679DF017AC9a2C0F1EF85Ec6BDAcB62fDA35273';
    var BNB_zkUSD = '0x1ad124FEcEa50400e833bF1Be920Ce360C18e5aC';
    var SNRK_ERA = '0x9C793E1f419276e68943eE2Dad9047Bd5e42594A';
    var TiTi_PEPERA = '0x87C51b89aA45EC428F41dB37B7Aa338f3e143a5d';
    var USDCe_zk_TiUSD = '0x9265D4865CeEB49D9E123E86FC7dc26c3A2601f3';
    var ZKDOGE_HOLD = '0x1B3971D508FDFe6C8713a06C3a4be7C3638f640f';
    var LUSD_ERA = '0x1c325Eb9E1E2B05b6958315EbE1E68EA31EAA913';
    var VS_Array = '0x4e5C8DCBDbCF015D43d8eACD999f703336108924';
    var USDC_USDCe = '0x554832c16cDAe197c11d94C9B53Aa273cDDF8388';
    var USDC_WETH = '0x705A506133481d5c1231f13BA68E0cF5B4ab64A5';
    var USDT_STAR = '0x52B566938a667E874F5BDD8302D32f8660c4665E';
    var MATIC_OT = '0x0C460a5bc485712cf960f496918Cdd627CC9F69b';
    var USDT_DCA = '0xc7ADc812d2C109CacB6f5A205dB950cD13a2DDA9';
    var USDC_SPACE = '0x1c8701Ae62eF3e5D3e0dB764Cc387f1C0D40a5e0';
    var USDC_ZAT = '0xd61658ad503230771fC8617AFC3E55531d1256D6';
    var USDC_USDT = '0xdbDB4CB8f991dE18199dF6621cDe222B63A7CBeE';
    var AAI_WETH = '0x531f0d8078D8ddd1B7f1c1df8d0D721483A73a0C';
    var ZK_WETH = '0x55ca894C51Cd38f1Da032619d3779Ab5ad34dAD2';
    var USDCe_ZK = '0xA984375b8E074D3e71F870da66FB49C894c35474';


    //WETH0_tToken
    if((tokens[0].address == WETH0 && tokens[1].address == tToken) || (tokens[1].address == WETH0 && tokens[0].address == tToken)){
        return WETH0_tToken;
    }
    //WETH0_tTokenB
    if((tokens[0].address == WETH0 && tokens[1].address == tTokenB) || (tokens[1].address == WETH0 && tokens[0].address == tTokenB)){
        return WETH0_tTokenB;
    }
    //tTokenB_tToken
    if((tokens[0].address == tTokenB && tokens[1].address == tToken) || (tokens[1].address == tTokenB && tokens[0].address == tToken)){
        return tTokenB_tToken;
    }
    //WETH_tToken
    if((tokens[0].address == WETH && tokens[1].address == tToken) || (tokens[1].address == WETH && tokens[0].address == tToken)){
        return WETH_tToken;
    }
    //WETH_tTokenB
    if((tokens[0].address == WETH && tokens[1].address == tTokenB) || (tokens[1].address == WETH && tokens[0].address == tTokenB)){
        return WETH_tTokenB;
    }
    //USDCe_WETH
    if((tokens[0].address == USDCe && tokens[1].address == WETH) || (tokens[1].address == USDCe && tokens[0].address == WETH)){
        return USDCe_WETH;
    }
    //WETH_TUSDT
    if((tokens[0].address == WETH && tokens[1].address == TUSDT) || (tokens[1].address == WETH && tokens[0].address == TUSDT)){
        return WETH_TUSDT;
    }
    //MUTE_WETH
    if((tokens[0].address == MUTE && tokens[1].address == WETH) || (tokens[1].address == MUTE && tokens[0].address == WETH)){
        return MUTE_WETH;
    }
    //WETH_ZKSHIBA
    if((tokens[0].address == WETH && tokens[1].address == ZKSHIBA) || (tokens[1].address == WETH && tokens[0].address == ZKSHIBA)){
        return WETH_ZKSHIBA;
    }
    //WETH_ZKDOGE
    if((tokens[0].address == WETH && tokens[1].address == ZKDOGE) || (tokens[1].address == WETH && tokens[0].address == ZKDOGE)){
        return WETH_ZKDOGE;
    }
    //WETH_BNB
    if((tokens[0].address == WETH && tokens[1].address == BNB) || (tokens[1].address == WETH && tokens[0].address == BNB)){
        return WETH_BNB;
    }
    //WETH_SLP
    if((tokens[0].address == WETH && tokens[1].address == SLP) || (tokens[1].address == WETH && tokens[0].address == SLP)){
        return WETH_SLP;
    }
    //WETH_TEST
    if((tokens[0].address == WETH && tokens[1].address == TEST) || (tokens[1].address == WETH && tokens[0].address == TEST)){
        return WETH_TEST;
    }
    //MUTE_USDCe
    if((tokens[0].address == MUTE && tokens[1].address == USDCe) || (tokens[1].address == MUTE && tokens[0].address == USDCe)){
        return MUTE_USDCe;
    }
    //WATERFALL_WETH
    if((tokens[0].address == WATERFALL && tokens[1].address == WETH) || (tokens[1].address == WATERFALL && tokens[0].address == WETH)){
        return WATERFALL_WETH;
    }
    //WETH_WATERFALL15
    if((tokens[0].address == WETH && tokens[1].address == WATERFALL15) || (tokens[1].address == WETH && tokens[0].address == WATERFALL15)){
        return WETH_WATERFALL15;
    }
    //USDCe_WATERFALL15
    if((tokens[0].address == USDCe && tokens[1].address == WATERFALL15) || (tokens[1].address == USDCe && tokens[0].address == WATERFALL15)){
        return USDCe_WATERFALL15;
    }
    //USDCe_SPACE
    if((tokens[0].address == USDCe && tokens[1].address == SPACE) || (tokens[1].address == USDCe && tokens[0].address == SPACE)){
        return USDCe_SPACE;
    }
    //SPACE_WETH
    if((tokens[0].address == SPACE && tokens[1].address == WETH) || (tokens[1].address == SPACE && tokens[0].address == WETH)){
        return SPACE_WETH;
    }
    //WETH_ZOHM
    if((tokens[0].address == WETH && tokens[1].address == ZOHM) || (tokens[1].address == WETH && tokens[0].address == ZOHM)){
        return WETH_ZOHM;
    }
    //WETH_OT
    if((tokens[0].address == WETH && tokens[1].address == OT) || (tokens[1].address == WETH && tokens[0].address == OT)){
        return WETH_OT;
    }
    //USDCe_OT
    if((tokens[0].address == USDCe && tokens[1].address == OT) || (tokens[1].address == USDCe && tokens[0].address == OT)){
        return USDCe_OT;
    }
    //WETH_DOF
    if((tokens[0].address == WETH && tokens[1].address == DOF) || (tokens[1].address == WETH && tokens[0].address == DOF)){
        return WETH_DOF;
    }
    //WETH_VC
    if((tokens[0].address == WETH && tokens[1].address == VC) || (tokens[1].address == WETH && tokens[0].address == VC)){
        return WETH_VC;
    }
    //USDCe_VC
    if((tokens[0].address == USDCe && tokens[1].address == VC) || (tokens[1].address == USDCe && tokens[0].address == VC)){
        return USDCe_VC;
    }
    //WETH_WISP
    if((tokens[0].address == WETH && tokens[1].address == WISP) || (tokens[1].address == WETH && tokens[0].address == WISP)){
        return WETH_WISP;
    }
    //USDCe_BUG
    if((tokens[0].address == USDCe && tokens[1].address == BUG) || (tokens[1].address == USDCe && tokens[0].address == BUG)){
        return USDCe_BUG;
    }
    //WETH_Cheems
    if((tokens[0].address == WETH && tokens[1].address == Cheems) || (tokens[1].address == WETH && tokens[0].address == Cheems)){
        return WETH_Cheems;
    }
    //USDCe_Cheems
    if((tokens[0].address == USDCe && tokens[1].address == Cheems) || (tokens[1].address == USDCe && tokens[0].address == Cheems)){
        return USDCe_Cheems;
    }
    //USDCe_ZAT
    if((tokens[0].address == USDCe && tokens[1].address == ZAT) || (tokens[1].address == USDCe && tokens[0].address == ZAT)){
        return USDCe_ZAT;
    }
    //BUSD_WETH
    if((tokens[0].address == BUSD && tokens[1].address == WETH) || (tokens[1].address == BUSD && tokens[0].address == WETH)){
        return BUSD_WETH;
    }
    //SPT_WETH
    if((tokens[0].address == SPT && tokens[1].address == WETH) || (tokens[1].address == SPT && tokens[0].address == WETH)){
        return SPT_WETH;
    }
    //SPT_SPACE
    if((tokens[0].address == SPT && tokens[1].address == SPACE) || (tokens[1].address == SPT && tokens[0].address == SPACE)){
        return SPT_SPACE;
    }
    //PGB_WETH
    if((tokens[0].address == PGB && tokens[1].address == WETH) || (tokens[1].address == PGB && tokens[0].address == WETH)){
        return PGB_WETH;
    }
    //BUSD_USDCe
    if((tokens[0].address == BUSD && tokens[1].address == USDCe) || (tokens[1].address == BUSD && tokens[0].address == USDCe)){
        return BUSD_USDCe;
    }
    //USDCe_USDT
    if((tokens[0].address == USDCe && tokens[1].address == USDT) || (tokens[1].address == USDCe && tokens[0].address == USDT)){
        return USDCe_USDT;
    }
    //USDT_WETH
    if((tokens[0].address == USDT && tokens[1].address == WETH) || (tokens[1].address == USDT && tokens[0].address == WETH)){
        return USDT_WETH;
    }
    //WETH_WBTC
    if((tokens[0].address == WETH && tokens[1].address == WBTC) || (tokens[1].address == WETH && tokens[0].address == WBTC)){
        return WETH_WBTC;
    }
    //USDCe_WBTC
    if((tokens[0].address == USDCe && tokens[1].address == WBTC) || (tokens[1].address == USDCe && tokens[0].address == WBTC)){
        return USDCe_WBTC;
    }
    //SIMPARB_WETH
    if((tokens[0].address == SIMPARB && tokens[1].address == WETH) || (tokens[1].address == SIMPARB && tokens[0].address == WETH)){
        return SIMPARB_WETH;
    }
    //WETH_PEPERA
    if((tokens[0].address == WETH && tokens[1].address == PEPERA) || (tokens[1].address == WETH && tokens[0].address == PEPERA)){
        return WETH_PEPERA;
    }
    //WETH_ZKST
    if((tokens[0].address == WETH && tokens[1].address == ZKST) || (tokens[1].address == WETH && tokens[0].address == ZKST)){
        return WETH_ZKST;
    }
    //WETH_ZKST2
    if((tokens[0].address == WETH && tokens[1].address == ZKST2) || (tokens[1].address == WETH && tokens[0].address == ZKST2)){
        return WETH_ZKST2;
    }
    //ZKST_ZKST2
    if((tokens[0].address == ZKST && tokens[1].address == ZKST2) || (tokens[1].address == ZKST && tokens[0].address == ZKST2)){
        return ZKST_ZKST2;
    }
    //SPACE_OT
    if((tokens[0].address == SPACE && tokens[1].address == OT) || (tokens[1].address == SPACE && tokens[0].address == OT)){
        return SPACE_OT;
    }
    //USDCe_ZKDOGE
    if((tokens[0].address == USDCe && tokens[1].address == ZKDOGE) || (tokens[1].address == USDCe && tokens[0].address == ZKDOGE)){
        return USDCe_ZKDOGE;
    }
    //ZAT_WETH
    if((tokens[0].address == ZAT && tokens[1].address == WETH) || (tokens[1].address == ZAT && tokens[0].address == WETH)){
        return ZAT_WETH;
    }
    //SPACE_USDT
    if((tokens[0].address == SPACE && tokens[1].address == USDT) || (tokens[1].address == SPACE && tokens[0].address == USDT)){
        return SPACE_USDT;
    }
    //LUSD_WETH
    if((tokens[0].address == LUSD && tokens[1].address == WETH) || (tokens[1].address == LUSD && tokens[0].address == WETH)){
        return LUSD_WETH;
    }
    //WETH_SIS
    if((tokens[0].address == WETH && tokens[1].address == SIS) || (tokens[1].address == WETH && tokens[0].address == SIS)){
        return WETH_SIS;
    }
    //USDT_USDADD
    if((tokens[0].address == USDT && tokens[1].address == USDADD) || (tokens[1].address == USDT && tokens[0].address == USDADD)){
        return USDT_USDADD;
    }
    //WETH_DOGERA
    if((tokens[0].address == WETH && tokens[1].address == DOGERA) || (tokens[1].address == WETH && tokens[0].address == DOGERA)){
        return WETH_DOGERA;
    }
    //WETH_PEPE
    if((tokens[0].address == WETH && tokens[1].address == PEPE) || (tokens[1].address == WETH && tokens[0].address == PEPE)){
        return WETH_PEPE;
    }
    //SPACE_DVF
    if((tokens[0].address == SPACE && tokens[1].address == DVF) || (tokens[1].address == SPACE && tokens[0].address == DVF)){
        return SPACE_DVF;
    }
    //MUTE_ZKDOGE
    if((tokens[0].address == MUTE && tokens[1].address == ZKDOGE) || (tokens[1].address == MUTE && tokens[0].address == ZKDOGE)){
        return MUTE_ZKDOGE;
    }
    //WETH_ERA
    if((tokens[0].address == WETH && tokens[1].address == ERA) || (tokens[1].address == WETH && tokens[0].address == ERA)){
        return WETH_ERA;
    }
    //LSD_WETH
    if((tokens[0].address == LSD && tokens[1].address == WETH) || (tokens[1].address == LSD && tokens[0].address == WETH)){
        return LSD_WETH;
    }
    //OT_SIS
    if((tokens[0].address == OT && tokens[1].address == SIS) || (tokens[1].address == OT && tokens[0].address == SIS)){
        return OT_SIS;
    }
    //SPACE_zkUSD
    if((tokens[0].address == SPACE && tokens[1].address == zkUSD) || (tokens[1].address == SPACE && tokens[0].address == zkUSD)){
        return SPACE_zkUSD;
    }
    //WETH_spfi
    if((tokens[0].address == WETH && tokens[1].address == spfi) || (tokens[1].address == WETH && tokens[0].address == spfi)){
        return WETH_spfi;
    }
    //WETH_spfi2
    if((tokens[0].address == WETH && tokens[1].address == spfi2) || (tokens[1].address == WETH && tokens[0].address == spfi2)){
        return WETH_spfi2;
    }
    //spfi2_spfi
    if((tokens[0].address == spfi2 && tokens[1].address == spfi) || (tokens[1].address == spfi2 && tokens[0].address == spfi)){
        return spfi2_spfi;
    }
    //SPACE_Cheems
    if((tokens[0].address == SPACE && tokens[1].address == Cheems) || (tokens[1].address == SPACE && tokens[0].address == Cheems)){
        return SPACE_Cheems;
    }
    //WETH_AVAX
    if((tokens[0].address == WETH && tokens[1].address == AVAX) || (tokens[1].address == WETH && tokens[0].address == AVAX)){
        return WETH_AVAX;
    }
    //WETH_USDADD
    if((tokens[0].address == WETH && tokens[1].address == USDADD) || (tokens[1].address == WETH && tokens[0].address == USDADD)){
        return WETH_USDADD;
    }
    //SPACE_USDADD
    if((tokens[0].address == SPACE && tokens[1].address == USDADD) || (tokens[1].address == SPACE && tokens[0].address == USDADD)){
        return SPACE_USDADD;
    }
    //USDCe_zkUSD
    if((tokens[0].address == USDCe && tokens[1].address == zkUSD) || (tokens[1].address == USDCe && tokens[0].address == zkUSD)){
        return USDCe_zkUSD;
    }
    //SPACE_ERA
    if((tokens[0].address == SPACE && tokens[1].address == ERA) || (tokens[1].address == SPACE && tokens[0].address == ERA)){
        return SPACE_ERA;
    }
    //USDCe_LUSD
    if((tokens[0].address == USDCe && tokens[1].address == LUSD) || (tokens[1].address == USDCe && tokens[0].address == LUSD)){
        return USDCe_LUSD;
    }
    //SNRK_WETH
    if((tokens[0].address == SNRK && tokens[1].address == WETH) || (tokens[1].address == SNRK && tokens[0].address == WETH)){
        return SNRK_WETH;
    }
    //USDT_SIS
    if((tokens[0].address == USDT && tokens[1].address == SIS) || (tokens[1].address == USDT && tokens[0].address == SIS)){
        return USDT_SIS;
    }
    //ZAT_zkUSD
    if((tokens[0].address == ZAT && tokens[1].address == zkUSD) || (tokens[1].address == ZAT && tokens[0].address == zkUSD)){
        return ZAT_zkUSD;
    }
    //MUTE_SIS
    if((tokens[0].address == MUTE && tokens[1].address == SIS) || (tokens[1].address == MUTE && tokens[0].address == SIS)){
        return MUTE_SIS;
    }
    //USDCe_USDADD
    if((tokens[0].address == USDCe && tokens[1].address == USDADD) || (tokens[1].address == USDCe && tokens[0].address == USDADD)){
        return USDCe_USDADD;
    }
    //BUSD_LUSD
    if((tokens[0].address == BUSD && tokens[1].address == LUSD) || (tokens[1].address == BUSD && tokens[0].address == LUSD)){
        return BUSD_LUSD;
    }
    //ZPC_WETH
    if((tokens[0].address == ZPC && tokens[1].address == WETH) || (tokens[1].address == ZPC && tokens[0].address == WETH)){
        return ZPC_WETH;
    }
    //USDCe_BNB
    if((tokens[0].address == USDCe && tokens[1].address == BNB) || (tokens[1].address == USDCe && tokens[0].address == BNB)){
        return USDCe_BNB;
    }
    //USDT_OT
    if((tokens[0].address == USDT && tokens[1].address == OT) || (tokens[1].address == USDT && tokens[0].address == OT)){
        return USDT_OT;
    }
    //SPACE_ZAT
    if((tokens[0].address == SPACE && tokens[1].address == ZAT) || (tokens[1].address == SPACE && tokens[0].address == ZAT)){
        return SPACE_ZAT;
    }
    //WETH_zkSIMP
    if((tokens[0].address == WETH && tokens[1].address == zkSIMP) || (tokens[1].address == WETH && tokens[0].address == zkSIMP)){
        return WETH_zkSIMP;
    }
    //WETH_DVF
    if((tokens[0].address == WETH && tokens[1].address == DVF) || (tokens[1].address == WETH && tokens[0].address == DVF)){
        return WETH_DVF;
    }
    //MATIC_zkUSD
    if((tokens[0].address == MATIC && tokens[1].address == zkUSD) || (tokens[1].address == MATIC && tokens[0].address == zkUSD)){
        return MATIC_zkUSD;
    }
    //SPACE_LUSD
    if((tokens[0].address == SPACE && tokens[1].address == LUSD) || (tokens[1].address == SPACE && tokens[0].address == LUSD)){
        return SPACE_LUSD;
    }
    //USDCe_PEPE
    if((tokens[0].address == USDCe && tokens[1].address == PEPE) || (tokens[1].address == USDCe && tokens[0].address == PEPE)){
        return USDCe_PEPE;
    }
    //USDCe_Array
    if((tokens[0].address == USDCe && tokens[1].address == Array) || (tokens[1].address == USDCe && tokens[0].address == Array)){
        return USDCe_Array;
    }
    //WETH_zkUSD
    if((tokens[0].address == WETH && tokens[1].address == zkUSD) || (tokens[1].address == WETH && tokens[0].address == zkUSD)){
        return WETH_zkUSD;
    }
    //BUSD_USDT
    if((tokens[0].address == BUSD && tokens[1].address == USDT) || (tokens[1].address == BUSD && tokens[0].address == USDT)){
        return BUSD_USDT;
    }
    //SPACE_DOGERA
    if((tokens[0].address == SPACE && tokens[1].address == DOGERA) || (tokens[1].address == SPACE && tokens[0].address == DOGERA)){
        return SPACE_DOGERA;
    }
    //rETH_WETH
    if((tokens[0].address == rETH && tokens[1].address == WETH) || (tokens[1].address == rETH && tokens[0].address == WETH)){
        return rETH_WETH;
    }
    //SPACE_DCA
    if((tokens[0].address == SPACE && tokens[1].address == DCA) || (tokens[1].address == SPACE && tokens[0].address == DCA)){
        return SPACE_DCA;
    }
    //DOGERA_PEPE
    if((tokens[0].address == DOGERA && tokens[1].address == PEPE) || (tokens[1].address == DOGERA && tokens[0].address == PEPE)){
        return DOGERA_PEPE;
    }
    //ZAT_ERA
    if((tokens[0].address == ZAT && tokens[1].address == ERA) || (tokens[1].address == ZAT && tokens[0].address == ERA)){
        return ZAT_ERA;
    }
    //WETH_ROME
    if((tokens[0].address == WETH && tokens[1].address == ROME) || (tokens[1].address == WETH && tokens[0].address == ROME)){
        return WETH_ROME;
    }
    //BUSD_USDADD
    if((tokens[0].address == BUSD && tokens[1].address == USDADD) || (tokens[1].address == BUSD && tokens[0].address == USDADD)){
        return BUSD_USDADD;
    }
    //BUSD_SPACE
    if((tokens[0].address == BUSD && tokens[1].address == SPACE) || (tokens[1].address == BUSD && tokens[0].address == SPACE)){
        return BUSD_SPACE;
    }
    //BUSD_OT
    if((tokens[0].address == BUSD && tokens[1].address == OT) || (tokens[1].address == BUSD && tokens[0].address == OT)){
        return BUSD_OT;
    }
    //LUSD_SIS
    if((tokens[0].address == LUSD && tokens[1].address == SIS) || (tokens[1].address == LUSD && tokens[0].address == SIS)){
        return LUSD_SIS;
    }
    //ZAT_VC
    if((tokens[0].address == ZAT && tokens[1].address == VC) || (tokens[1].address == ZAT && tokens[0].address == VC)){
        return ZAT_VC;
    }
    //USDCe_ERA
    if((tokens[0].address == USDCe && tokens[1].address == ERA) || (tokens[1].address == USDCe && tokens[0].address == ERA)){
        return USDCe_ERA;
    }
    //WETH_Array
    if((tokens[0].address == WETH && tokens[1].address == Array) || (tokens[1].address == WETH && tokens[0].address == Array)){
        return WETH_Array;
    }
    //USDCe_AVAX
    if((tokens[0].address == USDCe && tokens[1].address == AVAX) || (tokens[1].address == USDCe && tokens[0].address == AVAX)){
        return USDCe_AVAX;
    }
    //MUTE_SPACE
    if((tokens[0].address == MUTE && tokens[1].address == SPACE) || (tokens[1].address == MUTE && tokens[0].address == SPACE)){
        return MUTE_SPACE;
    }
    //BUSD_BNB
    if((tokens[0].address == BUSD && tokens[1].address == BNB) || (tokens[1].address == BUSD && tokens[0].address == BNB)){
        return BUSD_BNB;
    }
    //USDCe_VS
    if((tokens[0].address == USDCe && tokens[1].address == VS) || (tokens[1].address == USDCe && tokens[0].address == VS)){
        return USDCe_VS;
    }
    //USDT_LUSD
    if((tokens[0].address == USDT && tokens[1].address == LUSD) || (tokens[1].address == USDT && tokens[0].address == LUSD)){
        return USDT_LUSD;
    }
    //KIT_WETH
    if((tokens[0].address == KIT && tokens[1].address == WETH) || (tokens[1].address == KIT && tokens[0].address == WETH)){
        return KIT_WETH;
    }
    //WETH_CEUSDC
    if((tokens[0].address == WETH && tokens[1].address == CEUSDC) || (tokens[1].address == WETH && tokens[0].address == CEUSDC)){
        return WETH_CEUSDC;
    }
    //USDT_PEPE
    if((tokens[0].address == USDT && tokens[1].address == PEPE) || (tokens[1].address == USDT && tokens[0].address == PEPE)){
        return USDT_PEPE;
    }
    //WETH_DCA
    if((tokens[0].address == WETH && tokens[1].address == DCA) || (tokens[1].address == WETH && tokens[0].address == DCA)){
        return WETH_DCA;
    }
    //MUTE_USDT
    if((tokens[0].address == MUTE && tokens[1].address == USDT) || (tokens[1].address == MUTE && tokens[0].address == USDT)){
        return MUTE_USDT;
    }
    //SPACE_WBTC
    if((tokens[0].address == SPACE && tokens[1].address == WBTC) || (tokens[1].address == SPACE && tokens[0].address == WBTC)){
        return SPACE_WBTC;
    }
    //ZPC_SNRK
    if((tokens[0].address == ZPC && tokens[1].address == SNRK) || (tokens[1].address == ZPC && tokens[0].address == SNRK)){
        return ZPC_SNRK;
    }
    //MATIC_SPACE
    if((tokens[0].address == MATIC && tokens[1].address == SPACE) || (tokens[1].address == MATIC && tokens[0].address == SPACE)){
        return MATIC_SPACE;
    }
    //SPACE_ZPC
    if((tokens[0].address == SPACE && tokens[1].address == ZPC) || (tokens[1].address == SPACE && tokens[0].address == ZPC)){
        return SPACE_ZPC;
    }
    //BUSD_Cheems
    if((tokens[0].address == BUSD && tokens[1].address == Cheems) || (tokens[1].address == BUSD && tokens[0].address == Cheems)){
        return BUSD_Cheems;
    }
    //LSD_SPACE
    if((tokens[0].address == LSD && tokens[1].address == SPACE) || (tokens[1].address == LSD && tokens[0].address == SPACE)){
        return LSD_SPACE;
    }
    //MUTE_ZAT
    if((tokens[0].address == MUTE && tokens[1].address == ZAT) || (tokens[1].address == MUTE && tokens[0].address == ZAT)){
        return MUTE_ZAT;
    }
    //USDCe_fuck
    if((tokens[0].address == USDCe && tokens[1].address == fuck) || (tokens[1].address == USDCe && tokens[0].address == fuck)){
        return USDCe_fuck;
    }
    //ZAT_DOGERA
    if((tokens[0].address == ZAT && tokens[1].address == DOGERA) || (tokens[1].address == ZAT && tokens[0].address == DOGERA)){
        return ZAT_DOGERA;
    }
    //zkAIRDROP_WETH
    if((tokens[0].address == zkAIRDROP && tokens[1].address == WETH) || (tokens[1].address == zkAIRDROP && tokens[0].address == WETH)){
        return zkAIRDROP_WETH;
    }
    //zkAIRDROP_Array
    if((tokens[0].address == zkAIRDROP && tokens[1].address == Array) || (tokens[1].address == zkAIRDROP && tokens[0].address == Array)){
        return zkAIRDROP_Array;
    }
    //WETH_zkAIRDROP121
    if((tokens[0].address == WETH && tokens[1].address == zkAIRDROP121) || (tokens[1].address == WETH && tokens[0].address == zkAIRDROP121)){
        return WETH_zkAIRDROP121;
    }
    //BUSD_zkUSD
    if((tokens[0].address == BUSD && tokens[1].address == zkUSD) || (tokens[1].address == BUSD && tokens[0].address == zkUSD)){
        return BUSD_zkUSD;
    }
    //SPACE_SNRK
    if((tokens[0].address == SPACE && tokens[1].address == SNRK) || (tokens[1].address == SPACE && tokens[0].address == SNRK)){
        return SPACE_SNRK;
    }
    //VS_WETH
    if((tokens[0].address == VS && tokens[1].address == WETH) || (tokens[1].address == VS && tokens[0].address == WETH)){
        return VS_WETH;
    }
    //ZAT_jgl
    if((tokens[0].address == ZAT && tokens[1].address == jgl) || (tokens[1].address == ZAT && tokens[0].address == jgl)){
        return ZAT_jgl;
    }
    //BUSD_WBTC
    if((tokens[0].address == BUSD && tokens[1].address == WBTC) || (tokens[1].address == BUSD && tokens[0].address == WBTC)){
        return BUSD_WBTC;
    }
    //WETH_pepe
    if((tokens[0].address == WETH && tokens[1].address == pepe) || (tokens[1].address == WETH && tokens[0].address == pepe)){
        return WETH_pepe;
    }
    //MUTE_VC
    if((tokens[0].address == MUTE && tokens[1].address == VC) || (tokens[1].address == MUTE && tokens[0].address == VC)){
        return MUTE_VC;
    }
    //ckn_zkUSD
    if((tokens[0].address == ckn && tokens[1].address == zkUSD) || (tokens[1].address == ckn && tokens[0].address == zkUSD)){
        return ckn_zkUSD;
    }
    //SPACE_MAV
    if((tokens[0].address == SPACE && tokens[1].address == MAV) || (tokens[1].address == SPACE && tokens[0].address == MAV)){
        return SPACE_MAV;
    }
    //MUTE_OT
    if((tokens[0].address == MUTE && tokens[1].address == OT) || (tokens[1].address == MUTE && tokens[0].address == OT)){
        return MUTE_OT;
    }
    //MATIC_USDCe
    if((tokens[0].address == MATIC && tokens[1].address == USDCe) || (tokens[1].address == MATIC && tokens[0].address == USDCe)){
        return MATIC_USDCe;
    }
    //LUSD_zkUSD
    if((tokens[0].address == LUSD && tokens[1].address == zkUSD) || (tokens[1].address == LUSD && tokens[0].address == zkUSD)){
        return LUSD_zkUSD;
    }
    //USDCe_DOGERA
    if((tokens[0].address == USDCe && tokens[1].address == DOGERA) || (tokens[1].address == USDCe && tokens[0].address == DOGERA)){
        return USDCe_DOGERA;
    }
    //SPACE_VC
    if((tokens[0].address == SPACE && tokens[1].address == VC) || (tokens[1].address == SPACE && tokens[0].address == VC)){
        return SPACE_VC;
    }
    //WETH_Mainnet_Token18
    if((tokens[0].address == WETH && tokens[1].address == Mainnet_Token18) || (tokens[1].address == WETH && tokens[0].address == Mainnet_Token18)){
        return WETH_Mainnet_Token18;
    }
    //WETH_vrf
    if((tokens[0].address == WETH && tokens[1].address == vrf) || (tokens[1].address == WETH && tokens[0].address == vrf)){
        return WETH_vrf;
    }
    //MUTE_AVAX
    if((tokens[0].address == MUTE && tokens[1].address == AVAX) || (tokens[1].address == MUTE && tokens[0].address == AVAX)){
        return MUTE_AVAX;
    }
    //PEPE2_WETH
    if((tokens[0].address == PEPE2 && tokens[1].address == WETH) || (tokens[1].address == PEPE2 && tokens[0].address == WETH)){
        return PEPE2_WETH;
    }
    //BUSD_MATIC
    if((tokens[0].address == BUSD && tokens[1].address == MATIC) || (tokens[1].address == BUSD && tokens[0].address == MATIC)){
        return BUSD_MATIC;
    }
    //LUSD_USDADD
    if((tokens[0].address == LUSD && tokens[1].address == USDADD) || (tokens[1].address == LUSD && tokens[0].address == USDADD)){
        return LUSD_USDADD;
    }
    //DOGERA_ERA
    if((tokens[0].address == DOGERA && tokens[1].address == ERA) || (tokens[1].address == DOGERA && tokens[0].address == ERA)){
        return DOGERA_ERA;
    }
    //SPACE_ZKDOGE
    if((tokens[0].address == SPACE && tokens[1].address == ZKDOGE) || (tokens[1].address == SPACE && tokens[0].address == ZKDOGE)){
        return SPACE_ZKDOGE;
    }
    //USDT_ERA
    if((tokens[0].address == USDT && tokens[1].address == ERA) || (tokens[1].address == USDT && tokens[0].address == ERA)){
        return USDT_ERA;
    }
    //USDT_zkUSD
    if((tokens[0].address == USDT && tokens[1].address == zkUSD) || (tokens[1].address == USDT && tokens[0].address == zkUSD)){
        return USDT_zkUSD;
    }
    //FRAX_zkUSD
    if((tokens[0].address == FRAX && tokens[1].address == zkUSD) || (tokens[1].address == FRAX && tokens[0].address == zkUSD)){
        return FRAX_zkUSD;
    }
    //MUTE_BUSD
    if((tokens[0].address == MUTE && tokens[1].address == BUSD) || (tokens[1].address == MUTE && tokens[0].address == BUSD)){
        return MUTE_BUSD;
    }
    //ZAT_Cheems
    if((tokens[0].address == ZAT && tokens[1].address == Cheems) || (tokens[1].address == ZAT && tokens[0].address == Cheems)){
        return ZAT_Cheems;
    }
    //ZPC_VC
    if((tokens[0].address == ZPC && tokens[1].address == VC) || (tokens[1].address == ZPC && tokens[0].address == VC)){
        return ZPC_VC;
    }
    //WETH_VZK
    if((tokens[0].address == WETH && tokens[1].address == VZK) || (tokens[1].address == WETH && tokens[0].address == VZK)){
        return WETH_VZK;
    }
    //MATIC_AVAX
    if((tokens[0].address == MATIC && tokens[1].address == AVAX) || (tokens[1].address == MATIC && tokens[0].address == AVAX)){
        return MATIC_AVAX;
    }
    //AVAX_BNB
    if((tokens[0].address == AVAX && tokens[1].address == BNB) || (tokens[1].address == AVAX && tokens[0].address == BNB)){
        return AVAX_BNB;
    }
    //Cheems_PEPE
    if((tokens[0].address == Cheems && tokens[1].address == PEPE) || (tokens[1].address == Cheems && tokens[0].address == PEPE)){
        return Cheems_PEPE;
    }
    //MATIC_WETH
    if((tokens[0].address == MATIC && tokens[1].address == WETH) || (tokens[1].address == MATIC && tokens[0].address == WETH)){
        return MATIC_WETH;
    }
    //rETH_Cheems
    if((tokens[0].address == rETH && tokens[1].address == Cheems) || (tokens[1].address == rETH && tokens[0].address == Cheems)){
        return rETH_Cheems;
    }
    //SNRK_DCA
    if((tokens[0].address == SNRK && tokens[1].address == DCA) || (tokens[1].address == SNRK && tokens[0].address == DCA)){
        return SNRK_DCA;
    }
    //SPACE_Array
    if((tokens[0].address == SPACE && tokens[1].address == Array) || (tokens[1].address == SPACE && tokens[0].address == Array)){
        return SPACE_Array;
    }
    //BNB_ERA
    if((tokens[0].address == BNB && tokens[1].address == ERA) || (tokens[1].address == BNB && tokens[0].address == ERA)){
        return BNB_ERA;
    }
    //Array_AVAX
    if((tokens[0].address == Array && tokens[1].address == AVAX) || (tokens[1].address == Array && tokens[0].address == AVAX)){
        return Array_AVAX;
    }
    //LSD_BNB
    if((tokens[0].address == LSD && tokens[1].address == BNB) || (tokens[1].address == LSD && tokens[0].address == BNB)){
        return LSD_BNB;
    }
    //BUSD_jgl
    if((tokens[0].address == BUSD && tokens[1].address == jgl) || (tokens[1].address == BUSD && tokens[0].address == jgl)){
        return BUSD_jgl;
    }
    //LUSD_Cheems
    if((tokens[0].address == LUSD && tokens[1].address == Cheems) || (tokens[1].address == LUSD && tokens[0].address == Cheems)){
        return LUSD_Cheems;
    }
    //USDCe_ZPC
    if((tokens[0].address == USDCe && tokens[1].address == ZPC) || (tokens[1].address == USDCe && tokens[0].address == ZPC)){
        return USDCe_ZPC;
    }
    //USDCe_DCA
    if((tokens[0].address == USDCe && tokens[1].address == DCA) || (tokens[1].address == USDCe && tokens[0].address == DCA)){
        return USDCe_DCA;
    }
    //WETH_ptls
    if((tokens[0].address == WETH && tokens[1].address == ptls) || (tokens[1].address == WETH && tokens[0].address == ptls)){
        return WETH_ptls;
    }
    //tkzk_WETH
    if((tokens[0].address == tkzk && tokens[1].address == WETH) || (tokens[1].address == tkzk && tokens[0].address == WETH)){
        return tkzk_WETH;
    }
    //Cheems_SIS
    if((tokens[0].address == Cheems && tokens[1].address == SIS) || (tokens[1].address == Cheems && tokens[0].address == SIS)){
        return Cheems_SIS;
    }
    //USDCe_SIS
    if((tokens[0].address == USDCe && tokens[1].address == SIS) || (tokens[1].address == USDCe && tokens[0].address == SIS)){
        return USDCe_SIS;
    }
    //BUSD_PEPE
    if((tokens[0].address == BUSD && tokens[1].address == PEPE) || (tokens[1].address == BUSD && tokens[0].address == PEPE)){
        return BUSD_PEPE;
    }
    //PEPERA_AVAX
    if((tokens[0].address == PEPERA && tokens[1].address == AVAX) || (tokens[1].address == PEPERA && tokens[0].address == AVAX)){
        return PEPERA_AVAX;
    }
    //MATIC_USDT
    if((tokens[0].address == MATIC && tokens[1].address == USDT) || (tokens[1].address == MATIC && tokens[0].address == USDT)){
        return MATIC_USDT;
    }
    //PEPERA_DCA
    if((tokens[0].address == PEPERA && tokens[1].address == DCA) || (tokens[1].address == PEPERA && tokens[0].address == DCA)){
        return PEPERA_DCA;
    }
    //WETH_CRV
    if((tokens[0].address == WETH && tokens[1].address == CRV) || (tokens[1].address == WETH && tokens[0].address == CRV)){
        return WETH_CRV;
    }
    //zkSHIB_WETH
    if((tokens[0].address == zkSHIB && tokens[1].address == WETH) || (tokens[1].address == zkSHIB && tokens[0].address == WETH)){
        return zkSHIB_WETH;
    }
    //LSD_ERA
    if((tokens[0].address == LSD && tokens[1].address == ERA) || (tokens[1].address == LSD && tokens[0].address == ERA)){
        return LSD_ERA;
    }
    //VC_PEPE
    if((tokens[0].address == VC && tokens[1].address == PEPE) || (tokens[1].address == VC && tokens[0].address == PEPE)){
        return VC_PEPE;
    }
    //OT_Cheems
    if((tokens[0].address == OT && tokens[1].address == Cheems) || (tokens[1].address == OT && tokens[0].address == Cheems)){
        return OT_Cheems;
    }
    //VS_Cheems
    if((tokens[0].address == VS && tokens[1].address == Cheems) || (tokens[1].address == VS && tokens[0].address == Cheems)){
        return VS_Cheems;
    }
    //Array_OT
    if((tokens[0].address == Array && tokens[1].address == OT) || (tokens[1].address == Array && tokens[0].address == OT)){
        return Array_OT;
    }
    //ZAT_USDT
    if((tokens[0].address == ZAT && tokens[1].address == USDT) || (tokens[1].address == ZAT && tokens[0].address == USDT)){
        return ZAT_USDT;
    }
    //USDCe_FRAX
    if((tokens[0].address == USDCe && tokens[1].address == FRAX) || (tokens[1].address == USDCe && tokens[0].address == FRAX)){
        return USDCe_FRAX;
    }
    //USDT_FRAX
    if((tokens[0].address == USDT && tokens[1].address == FRAX) || (tokens[1].address == USDT && tokens[0].address == FRAX)){
        return USDT_FRAX;
    }
    //WBTC_PEPE
    if((tokens[0].address == WBTC && tokens[1].address == PEPE) || (tokens[1].address == WBTC && tokens[0].address == PEPE)){
        return WBTC_PEPE;
    }
    //Array_Cheems
    if((tokens[0].address == Array && tokens[1].address == Cheems) || (tokens[1].address == Array && tokens[0].address == Cheems)){
        return Array_Cheems;
    }
    //BUSD_AVAX
    if((tokens[0].address == BUSD && tokens[1].address == AVAX) || (tokens[1].address == BUSD && tokens[0].address == AVAX)){
        return BUSD_AVAX;
    }
    //WETH_PAY8
    if((tokens[0].address == WETH && tokens[1].address == PAY8) || (tokens[1].address == WETH && tokens[0].address == PAY8)){
        return WETH_PAY8;
    }
    //WETH_META
    if((tokens[0].address == WETH && tokens[1].address == META) || (tokens[1].address == WETH && tokens[0].address == META)){
        return WETH_META;
    }
    //HODL_WETH
    if((tokens[0].address == HODL && tokens[1].address == WETH) || (tokens[1].address == HODL && tokens[0].address == WETH)){
        return HODL_WETH;
    }
    //TRYV_WETH
    if((tokens[0].address == TRYV && tokens[1].address == WETH) || (tokens[1].address == TRYV && tokens[0].address == WETH)){
        return TRYV_WETH;
    }
    //WETH_SCT
    if((tokens[0].address == WETH && tokens[1].address == SCT) || (tokens[1].address == WETH && tokens[0].address == SCT)){
        return WETH_SCT;
    }
    //WETH_VRTK
    if((tokens[0].address == WETH && tokens[1].address == VRTK) || (tokens[1].address == WETH && tokens[0].address == VRTK)){
        return WETH_VRTK;
    }
    //WETH_BTRM
    if((tokens[0].address == WETH && tokens[1].address == BTRM) || (tokens[1].address == WETH && tokens[0].address == BTRM)){
        return WETH_BTRM;
    }
    //WETH_KCAL
    if((tokens[0].address == WETH && tokens[1].address == KCAL) || (tokens[1].address == WETH && tokens[0].address == KCAL)){
        return WETH_KCAL;
    }
    //WETH_PIE
    if((tokens[0].address == WETH && tokens[1].address == PIE) || (tokens[1].address == WETH && tokens[0].address == PIE)){
        return WETH_PIE;
    }
    //WETH_TAKEDA
    if((tokens[0].address == WETH && tokens[1].address == TAKEDA) || (tokens[1].address == WETH && tokens[0].address == TAKEDA)){
        return WETH_TAKEDA;
    }
    //WETH_CICC
    if((tokens[0].address == WETH && tokens[1].address == CICC) || (tokens[1].address == WETH && tokens[0].address == CICC)){
        return WETH_CICC;
    }
    //AUSDC_WETH
    if((tokens[0].address == AUSDC && tokens[1].address == WETH) || (tokens[1].address == AUSDC && tokens[0].address == WETH)){
        return AUSDC_WETH;
    }
    //WETH_MONKEX
    if((tokens[0].address == WETH && tokens[1].address == MONKEX) || (tokens[1].address == WETH && tokens[0].address == MONKEX)){
        return WETH_MONKEX;
    }
    //WETH_BRS
    if((tokens[0].address == WETH && tokens[1].address == BRS) || (tokens[1].address == WETH && tokens[0].address == BRS)){
        return WETH_BRS;
    }
    //WETH_QUICK
    if((tokens[0].address == WETH && tokens[1].address == QUICK) || (tokens[1].address == WETH && tokens[0].address == QUICK)){
        return WETH_QUICK;
    }
    //WETH_XMR
    if((tokens[0].address == WETH && tokens[1].address == XMR) || (tokens[1].address == WETH && tokens[0].address == XMR)){
        return WETH_XMR;
    }
    //ARC_WETH
    if((tokens[0].address == ARC && tokens[1].address == WETH) || (tokens[1].address == ARC && tokens[0].address == WETH)){
        return ARC_WETH;
    }
    //WETH_DFL
    if((tokens[0].address == WETH && tokens[1].address == DFL) || (tokens[1].address == WETH && tokens[0].address == DFL)){
        return WETH_DFL;
    }
    //HBG_WETH
    if((tokens[0].address == HBG && tokens[1].address == WETH) || (tokens[1].address == HBG && tokens[0].address == WETH)){
        return HBG_WETH;
    }
    //FTI_WETH
    if((tokens[0].address == FTI && tokens[1].address == WETH) || (tokens[1].address == FTI && tokens[0].address == WETH)){
        return FTI_WETH;
    }
    //WETH_MOONION
    if((tokens[0].address == WETH && tokens[1].address == MOONION) || (tokens[1].address == WETH && tokens[0].address == MOONION)){
        return WETH_MOONION;
    }
    //WETH_HODL207
    if((tokens[0].address == WETH && tokens[1].address == HODL207) || (tokens[1].address == WETH && tokens[0].address == HODL207)){
        return WETH_HODL207;
    }
    //WETH_SHIBA
    if((tokens[0].address == WETH && tokens[1].address == SHIBA) || (tokens[1].address == WETH && tokens[0].address == SHIBA)){
        return WETH_SHIBA;
    }
    //WETH_VLTY
    if((tokens[0].address == WETH && tokens[1].address == VLTY) || (tokens[1].address == WETH && tokens[0].address == VLTY)){
        return WETH_VLTY;
    }
    //WETH_MRCH
    if((tokens[0].address == WETH && tokens[1].address == MRCH) || (tokens[1].address == WETH && tokens[0].address == MRCH)){
        return WETH_MRCH;
    }
    //WETH_UZUMAKI
    if((tokens[0].address == WETH && tokens[1].address == UZUMAKI) || (tokens[1].address == WETH && tokens[0].address == UZUMAKI)){
        return WETH_UZUMAKI;
    }
    //WETH_PTX
    if((tokens[0].address == WETH && tokens[1].address == PTX) || (tokens[1].address == WETH && tokens[0].address == PTX)){
        return WETH_PTX;
    }
    //WETH_NST
    if((tokens[0].address == WETH && tokens[1].address == NST) || (tokens[1].address == WETH && tokens[0].address == NST)){
        return WETH_NST;
    }
    //WETH_GEC
    if((tokens[0].address == WETH && tokens[1].address == GEC) || (tokens[1].address == WETH && tokens[0].address == GEC)){
        return WETH_GEC;
    }
    //WETH_C50
    if((tokens[0].address == WETH && tokens[1].address == C50) || (tokens[1].address == WETH && tokens[0].address == C50)){
        return WETH_C50;
    }
    //WETH_KAERI
    if((tokens[0].address == WETH && tokens[1].address == KAERI) || (tokens[1].address == WETH && tokens[0].address == KAERI)){
        return WETH_KAERI;
    }
    //WBTC217_WETH
    if((tokens[0].address == WBTC217 && tokens[1].address == WETH) || (tokens[1].address == WBTC217 && tokens[0].address == WETH)){
        return WBTC217_WETH;
    }
    //ISR_WETH
    if((tokens[0].address == ISR && tokens[1].address == WETH) || (tokens[1].address == ISR && tokens[0].address == WETH)){
        return ISR_WETH;
    }
    //JNS_WETH
    if((tokens[0].address == JNS && tokens[1].address == WETH) || (tokens[1].address == JNS && tokens[0].address == WETH)){
        return JNS_WETH;
    }
    //WETH_CVSHOT
    if((tokens[0].address == WETH && tokens[1].address == CVSHOT) || (tokens[1].address == WETH && tokens[0].address == CVSHOT)){
        return WETH_CVSHOT;
    }
    //ACE_WETH
    if((tokens[0].address == ACE && tokens[1].address == WETH) || (tokens[1].address == ACE && tokens[0].address == WETH)){
        return ACE_WETH;
    }
    //WETH_ABBC
    if((tokens[0].address == WETH && tokens[1].address == ABBC) || (tokens[1].address == WETH && tokens[0].address == ABBC)){
        return WETH_ABBC;
    }
    //WETH_ZWZ
    if((tokens[0].address == WETH && tokens[1].address == ZWZ) || (tokens[1].address == WETH && tokens[0].address == ZWZ)){
        return WETH_ZWZ;
    }
    //WETH_MNFT
    if((tokens[0].address == WETH && tokens[1].address == MNFT) || (tokens[1].address == WETH && tokens[0].address == MNFT)){
        return WETH_MNFT;
    }
    //WETH_MPS
    if((tokens[0].address == WETH && tokens[1].address == MPS) || (tokens[1].address == WETH && tokens[0].address == MPS)){
        return WETH_MPS;
    }
    //OAX_WETH
    if((tokens[0].address == OAX && tokens[1].address == WETH) || (tokens[1].address == OAX && tokens[0].address == WETH)){
        return OAX_WETH;
    }
    //WETH_USHI
    if((tokens[0].address == WETH && tokens[1].address == USHI) || (tokens[1].address == WETH && tokens[0].address == USHI)){
        return WETH_USHI;
    }
    //WETH_MTSKS
    if((tokens[0].address == WETH && tokens[1].address == MTSKS) || (tokens[1].address == WETH && tokens[0].address == MTSKS)){
        return WETH_MTSKS;
    }
    //WETH_FWW
    if((tokens[0].address == WETH && tokens[1].address == FWW) || (tokens[1].address == WETH && tokens[0].address == FWW)){
        return WETH_FWW;
    }
    //WETH_DAE
    if((tokens[0].address == WETH && tokens[1].address == DAE) || (tokens[1].address == WETH && tokens[0].address == DAE)){
        return WETH_DAE;
    }
    //WETH_PLY
    if((tokens[0].address == WETH && tokens[1].address == PLY) || (tokens[1].address == WETH && tokens[0].address == PLY)){
        return WETH_PLY;
    }
    //WETH_KIBA
    if((tokens[0].address == WETH && tokens[1].address == KIBA) || (tokens[1].address == WETH && tokens[0].address == KIBA)){
        return WETH_KIBA;
    }
    //WETH_BONDLY
    if((tokens[0].address == WETH && tokens[1].address == BONDLY) || (tokens[1].address == WETH && tokens[0].address == BONDLY)){
        return WETH_BONDLY;
    }
    //WETH_AHT
    if((tokens[0].address == WETH && tokens[1].address == AHT) || (tokens[1].address == WETH && tokens[0].address == AHT)){
        return WETH_AHT;
    }
    //VEN_WETH
    if((tokens[0].address == VEN && tokens[1].address == WETH) || (tokens[1].address == VEN && tokens[0].address == WETH)){
        return VEN_WETH;
    }
    //WETH_OOE
    if((tokens[0].address == WETH && tokens[1].address == OOE) || (tokens[1].address == WETH && tokens[0].address == OOE)){
        return WETH_OOE;
    }
    //DAM_WETH
    if((tokens[0].address == DAM && tokens[1].address == WETH) || (tokens[1].address == DAM && tokens[0].address == WETH)){
        return DAM_WETH;
    }
    //AGX_WETH
    if((tokens[0].address == AGX && tokens[1].address == WETH) || (tokens[1].address == AGX && tokens[0].address == WETH)){
        return AGX_WETH;
    }
    //SPACE239_WETH
    if((tokens[0].address == SPACE239 && tokens[1].address == WETH) || (tokens[1].address == SPACE239 && tokens[0].address == WETH)){
        return SPACE239_WETH;
    }
    //WETH_EGT
    if((tokens[0].address == WETH && tokens[1].address == EGT) || (tokens[1].address == WETH && tokens[0].address == EGT)){
        return WETH_EGT;
    }
    //WETH_FYD
    if((tokens[0].address == WETH && tokens[1].address == FYD) || (tokens[1].address == WETH && tokens[0].address == FYD)){
        return WETH_FYD;
    }
    //RPS_WETH
    if((tokens[0].address == RPS && tokens[1].address == WETH) || (tokens[1].address == RPS && tokens[0].address == WETH)){
        return RPS_WETH;
    }
    //WETH_CLINK
    if((tokens[0].address == WETH && tokens[1].address == CLINK) || (tokens[1].address == WETH && tokens[0].address == CLINK)){
        return WETH_CLINK;
    }
    //WETH_SOLBEAR
    if((tokens[0].address == WETH && tokens[1].address == SOLBEAR) || (tokens[1].address == WETH && tokens[0].address == SOLBEAR)){
        return WETH_SOLBEAR;
    }
    //WETH_KOM
    if((tokens[0].address == WETH && tokens[1].address == KOM) || (tokens[1].address == WETH && tokens[0].address == KOM)){
        return WETH_KOM;
    }
    //MNX_WETH
    if((tokens[0].address == MNX && tokens[1].address == WETH) || (tokens[1].address == MNX && tokens[0].address == WETH)){
        return MNX_WETH;
    }
    //ADEL_WETH
    if((tokens[0].address == ADEL && tokens[1].address == WETH) || (tokens[1].address == ADEL && tokens[0].address == WETH)){
        return ADEL_WETH;
    }
    //WETH_HDAO
    if((tokens[0].address == WETH && tokens[1].address == HDAO) || (tokens[1].address == WETH && tokens[0].address == HDAO)){
        return WETH_HDAO;
    }
    //WETH_EGG
    if((tokens[0].address == WETH && tokens[1].address == EGG) || (tokens[1].address == WETH && tokens[0].address == EGG)){
        return WETH_EGG;
    }
    //WETH_INN
    if((tokens[0].address == WETH && tokens[1].address == INN) || (tokens[1].address == WETH && tokens[0].address == INN)){
        return WETH_INN;
    }
    //SPEED_WETH
    if((tokens[0].address == SPEED && tokens[1].address == WETH) || (tokens[1].address == SPEED && tokens[0].address == WETH)){
        return SPEED_WETH;
    }
    //WETH_TANK
    if((tokens[0].address == WETH && tokens[1].address == TANK) || (tokens[1].address == WETH && tokens[0].address == TANK)){
        return WETH_TANK;
    }
    //WETH_RNB
    if((tokens[0].address == WETH && tokens[1].address == RNB) || (tokens[1].address == WETH && tokens[0].address == RNB)){
        return WETH_RNB;
    }
    //BMC_WETH
    if((tokens[0].address == BMC && tokens[1].address == WETH) || (tokens[1].address == BMC && tokens[0].address == WETH)){
        return BMC_WETH;
    }
    //WETH_SHDW
    if((tokens[0].address == WETH && tokens[1].address == SHDW) || (tokens[1].address == WETH && tokens[0].address == SHDW)){
        return WETH_SHDW;
    }
    //BABYFLOKI_WETH
    if((tokens[0].address == BABYFLOKI && tokens[1].address == WETH) || (tokens[1].address == BABYFLOKI && tokens[0].address == WETH)){
        return BABYFLOKI_WETH;
    }
    //METAI_WETH
    if((tokens[0].address == METAI && tokens[1].address == WETH) || (tokens[1].address == METAI && tokens[0].address == WETH)){
        return METAI_WETH;
    }
    //WETH_TIKKY
    if((tokens[0].address == WETH && tokens[1].address == TIKKY) || (tokens[1].address == WETH && tokens[0].address == TIKKY)){
        return WETH_TIKKY;
    }
    //MEISHU_WETH
    if((tokens[0].address == MEISHU && tokens[1].address == WETH) || (tokens[1].address == MEISHU && tokens[0].address == WETH)){
        return MEISHU_WETH;
    }
    //WETH_LGOLD
    if((tokens[0].address == WETH && tokens[1].address == LGOLD) || (tokens[1].address == WETH && tokens[0].address == LGOLD)){
        return WETH_LGOLD;
    }
    //rETH_USDT
    if((tokens[0].address == rETH && tokens[1].address == USDT) || (tokens[1].address == rETH && tokens[0].address == USDT)){
        return rETH_USDT;
    }
    //VC_OT
    if((tokens[0].address == VC && tokens[1].address == OT) || (tokens[1].address == VC && tokens[0].address == OT)){
        return VC_OT;
    }
    //BUSD_VS
    if((tokens[0].address == BUSD && tokens[1].address == VS) || (tokens[1].address == BUSD && tokens[0].address == VS)){
        return BUSD_VS;
    }
    //VS_OT
    if((tokens[0].address == VS && tokens[1].address == OT) || (tokens[1].address == VS && tokens[0].address == OT)){
        return VS_OT;
    }
    //ZAT_ZPC
    if((tokens[0].address == ZAT && tokens[1].address == ZPC) || (tokens[1].address == ZAT && tokens[0].address == ZPC)){
        return ZAT_ZPC;
    }
    //Cheems_zkUSD
    if((tokens[0].address == Cheems && tokens[1].address == zkUSD) || (tokens[1].address == Cheems && tokens[0].address == zkUSD)){
        return Cheems_zkUSD;
    }
    //BUSD_Array
    if((tokens[0].address == BUSD && tokens[1].address == Array) || (tokens[1].address == BUSD && tokens[0].address == Array)){
        return BUSD_Array;
    }
    //SPACE_AVAX
    if((tokens[0].address == SPACE && tokens[1].address == AVAX) || (tokens[1].address == SPACE && tokens[0].address == AVAX)){
        return SPACE_AVAX;
    }
    //USDT_Array
    if((tokens[0].address == USDT && tokens[1].address == Array) || (tokens[1].address == USDT && tokens[0].address == Array)){
        return USDT_Array;
    }
    //USDT_Cheems
    if((tokens[0].address == USDT && tokens[1].address == Cheems) || (tokens[1].address == USDT && tokens[0].address == Cheems)){
        return USDT_Cheems;
    }
    //USDT_WBTC
    if((tokens[0].address == USDT && tokens[1].address == WBTC) || (tokens[1].address == USDT && tokens[0].address == WBTC)){
        return USDT_WBTC;
    }
    //USDT_ZPC
    if((tokens[0].address == USDT && tokens[1].address == ZPC) || (tokens[1].address == USDT && tokens[0].address == ZPC)){
        return USDT_ZPC;
    }
    //USDCe_DVF
    if((tokens[0].address == USDCe && tokens[1].address == DVF) || (tokens[1].address == USDCe && tokens[0].address == DVF)){
        return USDCe_DVF;
    }
    //SPACE_PEPE
    if((tokens[0].address == SPACE && tokens[1].address == PEPE) || (tokens[1].address == SPACE && tokens[0].address == PEPE)){
        return SPACE_PEPE;
    }
    //DVF_zkUSD
    if((tokens[0].address == DVF && tokens[1].address == zkUSD) || (tokens[1].address == DVF && tokens[0].address == zkUSD)){
        return DVF_zkUSD;
    }
    //BNB_Cheems
    if((tokens[0].address == BNB && tokens[1].address == Cheems) || (tokens[1].address == BNB && tokens[0].address == Cheems)){
        return BNB_Cheems;
    }
    //MUTE_MATIC
    if((tokens[0].address == MUTE && tokens[1].address == MATIC) || (tokens[1].address == MUTE && tokens[0].address == MATIC)){
        return MUTE_MATIC;
    }
    //DCA_Cheems
    if((tokens[0].address == DCA && tokens[1].address == Cheems) || (tokens[1].address == DCA && tokens[0].address == Cheems)){
        return DCA_Cheems;
    }
    //ZF_WETH
    if((tokens[0].address == ZF && tokens[1].address == WETH) || (tokens[1].address == ZF && tokens[0].address == WETH)){
        return ZF_WETH;
    }
    //USDCe_LSD
    if((tokens[0].address == USDCe && tokens[1].address == LSD) || (tokens[1].address == USDCe && tokens[0].address == LSD)){
        return USDCe_LSD;
    }
    //USDT_BNB
    if((tokens[0].address == USDT && tokens[1].address == BNB) || (tokens[1].address == USDT && tokens[0].address == BNB)){
        return USDT_BNB;
    }
    //USDCe_SNRK
    if((tokens[0].address == USDCe && tokens[1].address == SNRK) || (tokens[1].address == USDCe && tokens[0].address == SNRK)){
        return USDCe_SNRK;
    }
    //SPACE_SIS
    if((tokens[0].address == SPACE && tokens[1].address == SIS) || (tokens[1].address == SPACE && tokens[0].address == SIS)){
        return SPACE_SIS;
    }
    //VS_AVAX
    if((tokens[0].address == VS && tokens[1].address == AVAX) || (tokens[1].address == VS && tokens[0].address == AVAX)){
        return VS_AVAX;
    }
    //ZAT_PEPERA
    if((tokens[0].address == ZAT && tokens[1].address == PEPERA) || (tokens[1].address == ZAT && tokens[0].address == PEPERA)){
        return ZAT_PEPERA;
    }
    //PEPERA_BNB
    if((tokens[0].address == PEPERA && tokens[1].address == BNB) || (tokens[1].address == PEPERA && tokens[0].address == BNB)){
        return PEPERA_BNB;
    }
    //Array_ERA
    if((tokens[0].address == Array && tokens[1].address == ERA) || (tokens[1].address == Array && tokens[0].address == ERA)){
        return Array_ERA;
    }
    //WETH_OmegaMoonProjectAlpha100BuyNow
    if((tokens[0].address == WETH && tokens[1].address == OmegaMoonProjectAlpha100BuyNow) || (tokens[1].address == WETH && tokens[0].address == OmegaMoonProjectAlpha100BuyNow)){
        return WETH_OmegaMoonProjectAlpha100BuyNow;
    }
    //WETH_FRAX
    if((tokens[0].address == WETH && tokens[1].address == FRAX) || (tokens[1].address == WETH && tokens[0].address == FRAX)){
        return WETH_FRAX;
    }
    //VS_WBTC
    if((tokens[0].address == VS && tokens[1].address == WBTC) || (tokens[1].address == VS && tokens[0].address == WBTC)){
        return VS_WBTC;
    }
    //WETH_yTycheV2
    if((tokens[0].address == WETH && tokens[1].address == yTycheV2) || (tokens[1].address == WETH && tokens[0].address == yTycheV2)){
        return WETH_yTycheV2;
    }
    //LUSD_ZKDOGE
    if((tokens[0].address == LUSD && tokens[1].address == ZKDOGE) || (tokens[1].address == LUSD && tokens[0].address == ZKDOGE)){
        return LUSD_ZKDOGE;
    }
    //SPACE_BNB
    if((tokens[0].address == SPACE && tokens[1].address == BNB) || (tokens[1].address == SPACE && tokens[0].address == BNB)){
        return SPACE_BNB;
    }
    //WETH_QB
    if((tokens[0].address == WETH && tokens[1].address == QB) || (tokens[1].address == WETH && tokens[0].address == QB)){
        return WETH_QB;
    }
    //LUSD_WBTC
    if((tokens[0].address == LUSD && tokens[1].address == WBTC) || (tokens[1].address == LUSD && tokens[0].address == WBTC)){
        return LUSD_WBTC;
    }
    //DOGERA_Cheems
    if((tokens[0].address == DOGERA && tokens[1].address == Cheems) || (tokens[1].address == DOGERA && tokens[0].address == Cheems)){
        return DOGERA_Cheems;
    }
    //DVF_Cheems
    if((tokens[0].address == DVF && tokens[1].address == Cheems) || (tokens[1].address == DVF && tokens[0].address == Cheems)){
        return DVF_Cheems;
    }
    //VC_ZKDOGE
    if((tokens[0].address == VC && tokens[1].address == ZKDOGE) || (tokens[1].address == VC && tokens[0].address == ZKDOGE)){
        return VC_ZKDOGE;
    }
    //SPACE_FRAX
    if((tokens[0].address == SPACE && tokens[1].address == FRAX) || (tokens[1].address == SPACE && tokens[0].address == FRAX)){
        return SPACE_FRAX;
    }
    //WBTC_zkUSD
    if((tokens[0].address == WBTC && tokens[1].address == zkUSD) || (tokens[1].address == WBTC && tokens[0].address == zkUSD)){
        return WBTC_zkUSD;
    }
    //WETH_WETH301
    if((tokens[0].address == WETH && tokens[1].address == WETH301) || (tokens[1].address == WETH && tokens[0].address == WETH301)){
        return WETH_WETH301;
    }
    //ZAT_SIS
    if((tokens[0].address == ZAT && tokens[1].address == SIS) || (tokens[1].address == ZAT && tokens[0].address == SIS)){
        return ZAT_SIS;
    }
    //SNRK_PEPERA
    if((tokens[0].address == SNRK && tokens[1].address == PEPERA) || (tokens[1].address == SNRK && tokens[0].address == PEPERA)){
        return SNRK_PEPERA;
    }
    //MUTE_Cheems
    if((tokens[0].address == MUTE && tokens[1].address == Cheems) || (tokens[1].address == MUTE && tokens[0].address == Cheems)){
        return MUTE_Cheems;
    }
    //ERA_PEPE
    if((tokens[0].address == ERA && tokens[1].address == PEPE) || (tokens[1].address == ERA && tokens[0].address == PEPE)){
        return ERA_PEPE;
    }
    //ZPC_DOGERA
    if((tokens[0].address == ZPC && tokens[1].address == DOGERA) || (tokens[1].address == ZPC && tokens[0].address == DOGERA)){
        return ZPC_DOGERA;
    }
    //USDADD_ZKDOGE
    if((tokens[0].address == USDADD && tokens[1].address == ZKDOGE) || (tokens[1].address == USDADD && tokens[0].address == ZKDOGE)){
        return USDADD_ZKDOGE;
    }
    //MATIC_Array
    if((tokens[0].address == MATIC && tokens[1].address == Array) || (tokens[1].address == MATIC && tokens[0].address == Array)){
        return MATIC_Array;
    }
    //USDCe_PEPERA
    if((tokens[0].address == USDCe && tokens[1].address == PEPERA) || (tokens[1].address == USDCe && tokens[0].address == PEPERA)){
        return USDCe_PEPERA;
    }
    //USDT_DOGERA
    if((tokens[0].address == USDT && tokens[1].address == DOGERA) || (tokens[1].address == USDT && tokens[0].address == DOGERA)){
        return USDT_DOGERA;
    }
    //AVAX_OT
    if((tokens[0].address == AVAX && tokens[1].address == OT) || (tokens[1].address == AVAX && tokens[0].address == OT)){
        return AVAX_OT;
    }
    //GOLD_WETH
    if((tokens[0].address == GOLD && tokens[1].address == WETH) || (tokens[1].address == GOLD && tokens[0].address == WETH)){
        return GOLD_WETH;
    }
    //DCA_SIS
    if((tokens[0].address == DCA && tokens[1].address == SIS) || (tokens[1].address == DCA && tokens[0].address == SIS)){
        return DCA_SIS;
    }
    //MUTE_WBTC
    if((tokens[0].address == MUTE && tokens[1].address == WBTC) || (tokens[1].address == MUTE && tokens[0].address == WBTC)){
        return MUTE_WBTC;
    }
    //BUSD_ZAT
    if((tokens[0].address == BUSD && tokens[1].address == ZAT) || (tokens[1].address == BUSD && tokens[0].address == ZAT)){
        return BUSD_ZAT;
    }
    //Array_DCA
    if((tokens[0].address == Array && tokens[1].address == DCA) || (tokens[1].address == Array && tokens[0].address == DCA)){
        return Array_DCA;
    }
    //ZKDOGE_Cheems
    if((tokens[0].address == ZKDOGE && tokens[1].address == Cheems) || (tokens[1].address == ZKDOGE && tokens[0].address == Cheems)){
        return ZKDOGE_Cheems;
    }
    //AVAX_SIS
    if((tokens[0].address == AVAX && tokens[1].address == SIS) || (tokens[1].address == AVAX && tokens[0].address == SIS)){
        return AVAX_SIS;
    }
    //USDCe_ONEZ
    if((tokens[0].address == USDCe && tokens[1].address == ONEZ) || (tokens[1].address == USDCe && tokens[0].address == ONEZ)){
        return USDCe_ONEZ;
    }
    //iUSD_SPACE
    if((tokens[0].address == iUSD && tokens[1].address == SPACE) || (tokens[1].address == iUSD && tokens[0].address == SPACE)){
        return iUSD_SPACE;
    }
    //USDT_AVAX
    if((tokens[0].address == USDT && tokens[1].address == AVAX) || (tokens[1].address == USDT && tokens[0].address == AVAX)){
        return USDT_AVAX;
    }
    //ZAT_USDADD
    if((tokens[0].address == ZAT && tokens[1].address == USDADD) || (tokens[1].address == ZAT && tokens[0].address == USDADD)){
        return ZAT_USDADD;
    }
    //BUSD_VC
    if((tokens[0].address == BUSD && tokens[1].address == VC) || (tokens[1].address == BUSD && tokens[0].address == VC)){
        return BUSD_VC;
    }
    //USDT_VC
    if((tokens[0].address == USDT && tokens[1].address == VC) || (tokens[1].address == USDT && tokens[0].address == VC)){
        return USDT_VC;
    }
    //rETH_WBTC
    if((tokens[0].address == rETH && tokens[1].address == WBTC) || (tokens[1].address == rETH && tokens[0].address == WBTC)){
        return rETH_WBTC;
    }
    //WETH_BEANY
    if((tokens[0].address == WETH && tokens[1].address == BEANY) || (tokens[1].address == WETH && tokens[0].address == BEANY)){
        return WETH_BEANY;
    }
    //ZAT_BNB
    if((tokens[0].address == ZAT && tokens[1].address == BNB) || (tokens[1].address == ZAT && tokens[0].address == BNB)){
        return ZAT_BNB;
    }
    //MUTE_Array
    if((tokens[0].address == MUTE && tokens[1].address == Array) || (tokens[1].address == MUTE && tokens[0].address == Array)){
        return MUTE_Array;
    }
    //USDCe_MAV
    if((tokens[0].address == USDCe && tokens[1].address == MAV) || (tokens[1].address == USDCe && tokens[0].address == MAV)){
        return USDCe_MAV;
    }
    //MAV_WBTC
    if((tokens[0].address == MAV && tokens[1].address == WBTC) || (tokens[1].address == MAV && tokens[0].address == WBTC)){
        return MAV_WBTC;
    }
    //iZi_USDCe
    if((tokens[0].address == iZi && tokens[1].address == USDCe) || (tokens[1].address == iZi && tokens[0].address == USDCe)){
        return iZi_USDCe;
    }
    //MUTE_zkUSD
    if((tokens[0].address == MUTE && tokens[1].address == zkUSD) || (tokens[1].address == MUTE && tokens[0].address == zkUSD)){
        return MUTE_zkUSD;
    }
    //WETH_ONEZ
    if((tokens[0].address == WETH && tokens[1].address == ONEZ) || (tokens[1].address == WETH && tokens[0].address == ONEZ)){
        return WETH_ONEZ;
    }
    //iZi_WETH
    if((tokens[0].address == iZi && tokens[1].address == WETH) || (tokens[1].address == iZi && tokens[0].address == WETH)){
        return iZi_WETH;
    }
    //WETH_MAV
    if((tokens[0].address == WETH && tokens[1].address == MAV) || (tokens[1].address == WETH && tokens[0].address == MAV)){
        return WETH_MAV;
    }
    //iZi_SPACE
    if((tokens[0].address == iZi && tokens[1].address == SPACE) || (tokens[1].address == iZi && tokens[0].address == SPACE)){
        return iZi_SPACE;
    }
    //yo_WETH
    if((tokens[0].address == yo && tokens[1].address == WETH) || (tokens[1].address == yo && tokens[0].address == WETH)){
        return yo_WETH;
    }
    //VC_Cheems
    if((tokens[0].address == VC && tokens[1].address == Cheems) || (tokens[1].address == VC && tokens[0].address == Cheems)){
        return VC_Cheems;
    }
    //rETH_LUSD
    if((tokens[0].address == rETH && tokens[1].address == LUSD) || (tokens[1].address == rETH && tokens[0].address == LUSD)){
        return rETH_LUSD;
    }
    //MUTE_MAV
    if((tokens[0].address == MUTE && tokens[1].address == MAV) || (tokens[1].address == MUTE && tokens[0].address == MAV)){
        return MUTE_MAV;
    }
    //USDT_MAV
    if((tokens[0].address == USDT && tokens[1].address == MAV) || (tokens[1].address == USDT && tokens[0].address == MAV)){
        return USDT_MAV;
    }
    //ZZ_WETH
    if((tokens[0].address == ZZ && tokens[1].address == WETH) || (tokens[1].address == ZZ && tokens[0].address == WETH)){
        return ZZ_WETH;
    }
    //WETH_WEFI
    if((tokens[0].address == WETH && tokens[1].address == WEFI) || (tokens[1].address == WETH && tokens[0].address == WEFI)){
        return WETH_WEFI;
    }
    //USDT_cbETH
    if((tokens[0].address == USDT && tokens[1].address == cbETH) || (tokens[1].address == USDT && tokens[0].address == cbETH)){
        return USDT_cbETH;
    }
    //BNB_OT
    if((tokens[0].address == BNB && tokens[1].address == OT) || (tokens[1].address == BNB && tokens[0].address == OT)){
        return BNB_OT;
    }
    //rETH_USDCe
    if((tokens[0].address == rETH && tokens[1].address == USDCe) || (tokens[1].address == rETH && tokens[0].address == USDCe)){
        return rETH_USDCe;
    }
    //MAV_DOGERA
    if((tokens[0].address == MAV && tokens[1].address == DOGERA) || (tokens[1].address == MAV && tokens[0].address == DOGERA)){
        return MAV_DOGERA;
    }
    //USDT_ZKDOGE
    if((tokens[0].address == USDT && tokens[1].address == ZKDOGE) || (tokens[1].address == USDT && tokens[0].address == ZKDOGE)){
        return USDT_ZKDOGE;
    }
    //ZAT_Array
    if((tokens[0].address == ZAT && tokens[1].address == Array) || (tokens[1].address == ZAT && tokens[0].address == Array)){
        return ZAT_Array;
    }
    //ZAT_DVF
    if((tokens[0].address == ZAT && tokens[1].address == DVF) || (tokens[1].address == ZAT && tokens[0].address == DVF)){
        return ZAT_DVF;
    }
    //ZAT_BEANY
    if((tokens[0].address == ZAT && tokens[1].address == BEANY) || (tokens[1].address == ZAT && tokens[0].address == BEANY)){
        return ZAT_BEANY;
    }
    //BNB_MAV
    if((tokens[0].address == BNB && tokens[1].address == MAV) || (tokens[1].address == BNB && tokens[0].address == MAV)){
        return BNB_MAV;
    }
    //ZAT_MAV
    if((tokens[0].address == ZAT && tokens[1].address == MAV) || (tokens[1].address == ZAT && tokens[0].address == MAV)){
        return ZAT_MAV;
    }
    //MATIC_ZKDOGE
    if((tokens[0].address == MATIC && tokens[1].address == ZKDOGE) || (tokens[1].address == MATIC && tokens[0].address == ZKDOGE)){
        return MATIC_ZKDOGE;
    }
    //iZi_VC
    if((tokens[0].address == iZi && tokens[1].address == VC) || (tokens[1].address == iZi && tokens[0].address == VC)){
        return iZi_VC;
    }
    //LUSD_VC
    if((tokens[0].address == LUSD && tokens[1].address == VC) || (tokens[1].address == LUSD && tokens[0].address == VC)){
        return LUSD_VC;
    }
    //LUSD_MAV
    if((tokens[0].address == LUSD && tokens[1].address == MAV) || (tokens[1].address == LUSD && tokens[0].address == MAV)){
        return LUSD_MAV;
    }
    //VC_SIS
    if((tokens[0].address == VC && tokens[1].address == SIS) || (tokens[1].address == VC && tokens[0].address == SIS)){
        return VC_SIS;
    }
    //iZi_SIS
    if((tokens[0].address == iZi && tokens[1].address == SIS) || (tokens[1].address == iZi && tokens[0].address == SIS)){
        return iZi_SIS;
    }
    //WETH_FUL
    if((tokens[0].address == WETH && tokens[1].address == FUL) || (tokens[1].address == WETH && tokens[0].address == FUL)){
        return WETH_FUL;
    }
    //USDT_PEPERA
    if((tokens[0].address == USDT && tokens[1].address == PEPERA) || (tokens[1].address == USDT && tokens[0].address == PEPERA)){
        return USDT_PEPERA;
    }
    //USDADD_WBTC
    if((tokens[0].address == USDADD && tokens[1].address == WBTC) || (tokens[1].address == USDADD && tokens[0].address == WBTC)){
        return USDADD_WBTC;
    }
    //AVAX_VC
    if((tokens[0].address == AVAX && tokens[1].address == VC) || (tokens[1].address == AVAX && tokens[0].address == VC)){
        return AVAX_VC;
    }
    //ZAT_ZKDOGE
    if((tokens[0].address == ZAT && tokens[1].address == ZKDOGE) || (tokens[1].address == ZAT && tokens[0].address == ZKDOGE)){
        return ZAT_ZKDOGE;
    }
    //MUTE_VS
    if((tokens[0].address == MUTE && tokens[1].address == VS) || (tokens[1].address == MUTE && tokens[0].address == VS)){
        return MUTE_VS;
    }
    //ONEZ_DVF
    if((tokens[0].address == ONEZ && tokens[1].address == DVF) || (tokens[1].address == ONEZ && tokens[0].address == DVF)){
        return ONEZ_DVF;
    }
    //ZPC_Cheems
    if((tokens[0].address == ZPC && tokens[1].address == Cheems) || (tokens[1].address == ZPC && tokens[0].address == Cheems)){
        return ZPC_Cheems;
    }
    //USDADD_zkUSD
    if((tokens[0].address == USDADD && tokens[1].address == zkUSD) || (tokens[1].address == USDADD && tokens[0].address == zkUSD)){
        return USDADD_zkUSD;
    }
    //WBTC_ZKDOGE
    if((tokens[0].address == WBTC && tokens[1].address == ZKDOGE) || (tokens[1].address == WBTC && tokens[0].address == ZKDOGE)){
        return WBTC_ZKDOGE;
    }
    //BUSD_MAV
    if((tokens[0].address == BUSD && tokens[1].address == MAV) || (tokens[1].address == BUSD && tokens[0].address == MAV)){
        return BUSD_MAV;
    }
    //MAV_ZKDOGE
    if((tokens[0].address == MAV && tokens[1].address == ZKDOGE) || (tokens[1].address == MAV && tokens[0].address == ZKDOGE)){
        return MAV_ZKDOGE;
    }
    //ZAT_WBTC
    if((tokens[0].address == ZAT && tokens[1].address == WBTC) || (tokens[1].address == ZAT && tokens[0].address == WBTC)){
        return ZAT_WBTC;
    }
    //iZi_Array
    if((tokens[0].address == iZi && tokens[1].address == Array) || (tokens[1].address == iZi && tokens[0].address == Array)){
        return iZi_Array;
    }
    //GOVI_zkUSD
    if((tokens[0].address == GOVI && tokens[1].address == zkUSD) || (tokens[1].address == GOVI && tokens[0].address == zkUSD)){
        return GOVI_zkUSD;
    }
    //USDT_DVF
    if((tokens[0].address == USDT && tokens[1].address == DVF) || (tokens[1].address == USDT && tokens[0].address == DVF)){
        return USDT_DVF;
    }
    //ZAT_OT
    if((tokens[0].address == ZAT && tokens[1].address == OT) || (tokens[1].address == ZAT && tokens[0].address == OT)){
        return ZAT_OT;
    }
    //OT_zkUSD
    if((tokens[0].address == OT && tokens[1].address == zkUSD) || (tokens[1].address == OT && tokens[0].address == zkUSD)){
        return OT_zkUSD;
    }
    //MATIC_PEPE
    if((tokens[0].address == MATIC && tokens[1].address == PEPE) || (tokens[1].address == MATIC && tokens[0].address == PEPE)){
        return MATIC_PEPE;
    }
    //iZi_USDT
    if((tokens[0].address == iZi && tokens[1].address == USDT) || (tokens[1].address == iZi && tokens[0].address == USDT)){
        return iZi_USDT;
    }
    //ZZ_ZAT
    if((tokens[0].address == ZZ && tokens[1].address == ZAT) || (tokens[1].address == ZZ && tokens[0].address == ZAT)){
        return ZZ_ZAT;
    }
    //Array_PEPE
    if((tokens[0].address == Array && tokens[1].address == PEPE) || (tokens[1].address == Array && tokens[0].address == PEPE)){
        return Array_PEPE;
    }
    //MATIC_VC
    if((tokens[0].address == MATIC && tokens[1].address == VC) || (tokens[1].address == MATIC && tokens[0].address == VC)){
        return MATIC_VC;
    }
    //uGOLD_Cheems
    if((tokens[0].address == uGOLD && tokens[1].address == Cheems) || (tokens[1].address == uGOLD && tokens[0].address == Cheems)){
        return uGOLD_Cheems;
    }
    //USDT_GOVI
    if((tokens[0].address == USDT && tokens[1].address == GOVI) || (tokens[1].address == USDT && tokens[0].address == GOVI)){
        return USDT_GOVI;
    }
    //iZi_BUSD
    if((tokens[0].address == iZi && tokens[1].address == BUSD) || (tokens[1].address == iZi && tokens[0].address == BUSD)){
        return iZi_BUSD;
    }
    //BUSD_rETH
    if((tokens[0].address == BUSD && tokens[1].address == rETH) || (tokens[1].address == BUSD && tokens[0].address == rETH)){
        return BUSD_rETH;
    }
    //iZi_LUSD
    if((tokens[0].address == iZi && tokens[1].address == LUSD) || (tokens[1].address == iZi && tokens[0].address == LUSD)){
        return iZi_LUSD;
    }
    //iZi_ZAT
    if((tokens[0].address == iZi && tokens[1].address == ZAT) || (tokens[1].address == iZi && tokens[0].address == ZAT)){
        return iZi_ZAT;
    }
    //rETH_ZKDOGE
    if((tokens[0].address == rETH && tokens[1].address == ZKDOGE) || (tokens[1].address == rETH && tokens[0].address == ZKDOGE)){
        return rETH_ZKDOGE;
    }
    //VS_VC
    if((tokens[0].address == VS && tokens[1].address == VC) || (tokens[1].address == VS && tokens[0].address == VC)){
        return VS_VC;
    }
    //VS_MAV
    if((tokens[0].address == VS && tokens[1].address == MAV) || (tokens[1].address == VS && tokens[0].address == MAV)){
        return VS_MAV;
    }
    //DOGERA_OT
    if((tokens[0].address == DOGERA && tokens[1].address == OT) || (tokens[1].address == DOGERA && tokens[0].address == OT)){
        return DOGERA_OT;
    }
    //iZi_ZKDOGE
    if((tokens[0].address == iZi && tokens[1].address == ZKDOGE) || (tokens[1].address == iZi && tokens[0].address == ZKDOGE)){
        return iZi_ZKDOGE;
    }
    //iZi_WBTC
    if((tokens[0].address == iZi && tokens[1].address == WBTC) || (tokens[1].address == iZi && tokens[0].address == WBTC)){
        return iZi_WBTC;
    }
    //iZi_BNB
    if((tokens[0].address == iZi && tokens[1].address == BNB) || (tokens[1].address == iZi && tokens[0].address == BNB)){
        return iZi_BNB;
    }
    //ZPC_BEANY
    if((tokens[0].address == ZPC && tokens[1].address == BEANY) || (tokens[1].address == ZPC && tokens[0].address == BEANY)){
        return ZPC_BEANY;
    }
    //WETH_ZKPEPE
    if((tokens[0].address == WETH && tokens[1].address == ZKPEPE) || (tokens[1].address == WETH && tokens[0].address == ZKPEPE)){
        return WETH_ZKPEPE;
    }
    //USDCe_GOVI
    if((tokens[0].address == USDCe && tokens[1].address == GOVI) || (tokens[1].address == USDCe && tokens[0].address == GOVI)){
        return USDCe_GOVI;
    }
    //SNRK_OT
    if((tokens[0].address == SNRK && tokens[1].address == OT) || (tokens[1].address == SNRK && tokens[0].address == OT)){
        return SNRK_OT;
    }
    //MAV_VC
    if((tokens[0].address == MAV && tokens[1].address == VC) || (tokens[1].address == MAV && tokens[0].address == VC)){
        return MAV_VC;
    }
    //WETH_KAT
    if((tokens[0].address == WETH && tokens[1].address == KAT) || (tokens[1].address == WETH && tokens[0].address == KAT)){
        return WETH_KAT;
    }
    //WETH_HOLD
    if((tokens[0].address == WETH && tokens[1].address == HOLD) || (tokens[1].address == WETH && tokens[0].address == HOLD)){
        return WETH_HOLD;
    }
    //USDCe_cbETH
    if((tokens[0].address == USDCe && tokens[1].address == cbETH) || (tokens[1].address == USDCe && tokens[0].address == cbETH)){
        return USDCe_cbETH;
    }
    //SPACE_KAT
    if((tokens[0].address == SPACE && tokens[1].address == KAT) || (tokens[1].address == SPACE && tokens[0].address == KAT)){
        return SPACE_KAT;
    }
    //WETH_GOVI
    if((tokens[0].address == WETH && tokens[1].address == GOVI) || (tokens[1].address == WETH && tokens[0].address == GOVI)){
        return WETH_GOVI;
    }
    //ZZ_USDT
    if((tokens[0].address == ZZ && tokens[1].address == USDT) || (tokens[1].address == ZZ && tokens[0].address == USDT)){
        return ZZ_USDT;
    }
    //USDT_HOLD
    if((tokens[0].address == USDT && tokens[1].address == HOLD) || (tokens[1].address == USDT && tokens[0].address == HOLD)){
        return USDT_HOLD;
    }
    //USDT_VS
    if((tokens[0].address == USDT && tokens[1].address == VS) || (tokens[1].address == USDT && tokens[0].address == VS)){
        return USDT_VS;
    }
    //LSD_Cheems
    if((tokens[0].address == LSD && tokens[1].address == Cheems) || (tokens[1].address == LSD && tokens[0].address == Cheems)){
        return LSD_Cheems;
    }
    //USDCe_BEANY
    if((tokens[0].address == USDCe && tokens[1].address == BEANY) || (tokens[1].address == USDCe && tokens[0].address == BEANY)){
        return USDCe_BEANY;
    }
    //SPACE_FUL
    if((tokens[0].address == SPACE && tokens[1].address == FUL) || (tokens[1].address == SPACE && tokens[0].address == FUL)){
        return SPACE_FUL;
    }
    //LSD_zkUSD
    if((tokens[0].address == LSD && tokens[1].address == zkUSD) || (tokens[1].address == LSD && tokens[0].address == zkUSD)){
        return LSD_zkUSD;
    }
    //USDCe_HOLD
    if((tokens[0].address == USDCe && tokens[1].address == HOLD) || (tokens[1].address == USDCe && tokens[0].address == HOLD)){
        return USDCe_HOLD;
    }
    //iZi_ERA
    if((tokens[0].address == iZi && tokens[1].address == ERA) || (tokens[1].address == iZi && tokens[0].address == ERA)){
        return iZi_ERA;
    }
    //Array_SIS
    if((tokens[0].address == Array && tokens[1].address == SIS) || (tokens[1].address == Array && tokens[0].address == SIS)){
        return Array_SIS;
    }
    //BNB_PEPE
    if((tokens[0].address == BNB && tokens[1].address == PEPE) || (tokens[1].address == BNB && tokens[0].address == PEPE)){
        return BNB_PEPE;
    }
    //BUSD_GOVI
    if((tokens[0].address == BUSD && tokens[1].address == GOVI) || (tokens[1].address == BUSD && tokens[0].address == GOVI)){
        return BUSD_GOVI;
    }
    //MUTE_USDADD
    if((tokens[0].address == MUTE && tokens[1].address == USDADD) || (tokens[1].address == MUTE && tokens[0].address == USDADD)){
        return MUTE_USDADD;
    }
    //BNB_ZKDOGE
    if((tokens[0].address == BNB && tokens[1].address == ZKDOGE) || (tokens[1].address == BNB && tokens[0].address == ZKDOGE)){
        return BNB_ZKDOGE;
    }
    //STAR_USDCe
    if((tokens[0].address == STAR && tokens[1].address == USDCe) || (tokens[1].address == STAR && tokens[0].address == USDCe)){
        return STAR_USDCe;
    }
    //STAR_WETH
    if((tokens[0].address == STAR && tokens[1].address == WETH) || (tokens[1].address == STAR && tokens[0].address == WETH)){
        return STAR_WETH;
    }
    //MATIC_USDADD
    if((tokens[0].address == MATIC && tokens[1].address == USDADD) || (tokens[1].address == MATIC && tokens[0].address == USDADD)){
        return MATIC_USDADD;
    }
    //iZi_DOGERA
    if((tokens[0].address == iZi && tokens[1].address == DOGERA) || (tokens[1].address == iZi && tokens[0].address == DOGERA)){
        return iZi_DOGERA;
    }
    //USDADD_Cheems
    if((tokens[0].address == USDADD && tokens[1].address == Cheems) || (tokens[1].address == USDADD && tokens[0].address == Cheems)){
        return USDADD_Cheems;
    }
    //USDCe_STAR425
    if((tokens[0].address == USDCe && tokens[1].address == STAR425) || (tokens[1].address == USDCe && tokens[0].address == STAR425)){
        return USDCe_STAR425;
    }
    //WETH_STAR425
    if((tokens[0].address == WETH && tokens[1].address == STAR425) || (tokens[1].address == WETH && tokens[0].address == STAR425)){
        return WETH_STAR425;
    }
    //MATIC_FRAX
    if((tokens[0].address == MATIC && tokens[1].address == FRAX) || (tokens[1].address == MATIC && tokens[0].address == FRAX)){
        return MATIC_FRAX;
    }
    //TiTi_zkUSD
    if((tokens[0].address == TiTi && tokens[1].address == zkUSD) || (tokens[1].address == TiTi && tokens[0].address == zkUSD)){
        return TiTi_zkUSD;
    }
    //OT_GOVI
    if((tokens[0].address == OT && tokens[1].address == GOVI) || (tokens[1].address == OT && tokens[0].address == GOVI)){
        return OT_GOVI;
    }
    //USDCe_KAT
    if((tokens[0].address == USDCe && tokens[1].address == KAT) || (tokens[1].address == USDCe && tokens[0].address == KAT)){
        return USDCe_KAT;
    }
    //WETH_cbETH
    if((tokens[0].address == WETH && tokens[1].address == cbETH) || (tokens[1].address == WETH && tokens[0].address == cbETH)){
        return WETH_cbETH;
    }
    //rETH_PEPERA
    if((tokens[0].address == rETH && tokens[1].address == PEPERA) || (tokens[1].address == rETH && tokens[0].address == PEPERA)){
        return rETH_PEPERA;
    }
    //BNB_FRAX
    if((tokens[0].address == BNB && tokens[1].address == FRAX) || (tokens[1].address == BNB && tokens[0].address == FRAX)){
        return BNB_FRAX;
    }
    //WETH_zk_TiUSD
    if((tokens[0].address == WETH && tokens[1].address == zk_TiUSD) || (tokens[1].address == WETH && tokens[0].address == zk_TiUSD)){
        return WETH_zk_TiUSD;
    }
    //SPACE_STAR425
    if((tokens[0].address == SPACE && tokens[1].address == STAR425) || (tokens[1].address == SPACE && tokens[0].address == STAR425)){
        return SPACE_STAR425;
    }
    //BNB_zkUSD
    if((tokens[0].address == BNB && tokens[1].address == zkUSD) || (tokens[1].address == BNB && tokens[0].address == zkUSD)){
        return BNB_zkUSD;
    }
    //SNRK_ERA
    if((tokens[0].address == SNRK && tokens[1].address == ERA) || (tokens[1].address == SNRK && tokens[0].address == ERA)){
        return SNRK_ERA;
    }
    //TiTi_PEPERA
    if((tokens[0].address == TiTi && tokens[1].address == PEPERA) || (tokens[1].address == TiTi && tokens[0].address == PEPERA)){
        return TiTi_PEPERA;
    }
    //USDCe_zk_TiUSD
    if((tokens[0].address == USDCe && tokens[1].address == zk_TiUSD) || (tokens[1].address == USDCe && tokens[0].address == zk_TiUSD)){
        return USDCe_zk_TiUSD;
    }
    //ZKDOGE_HOLD
    if((tokens[0].address == ZKDOGE && tokens[1].address == HOLD) || (tokens[1].address == ZKDOGE && tokens[0].address == HOLD)){
        return ZKDOGE_HOLD;
    }
    //LUSD_ERA
    if((tokens[0].address == LUSD && tokens[1].address == ERA) || (tokens[1].address == LUSD && tokens[0].address == ERA)){
        return LUSD_ERA;
    }
    //VS_Array
    if((tokens[0].address == VS && tokens[1].address == Array) || (tokens[1].address == VS && tokens[0].address == Array)){
        return VS_Array;
    }
    //USDC_USDCe
    if((tokens[0].address == USDC && tokens[1].address == USDCe) || (tokens[1].address == USDC && tokens[0].address == USDCe)){
        return USDC_USDCe;
    }
    //USDC_WETH
    if((tokens[0].address == USDC && tokens[1].address == WETH) || (tokens[1].address == USDC && tokens[0].address == WETH)){
        return USDC_WETH;
    }
    //USDT_STAR
    if((tokens[0].address == USDT && tokens[1].address == STAR) || (tokens[1].address == USDT && tokens[0].address == STAR)){
        return USDT_STAR;
    }
    //MATIC_OT
    if((tokens[0].address == MATIC && tokens[1].address == OT) || (tokens[1].address == MATIC && tokens[0].address == OT)){
        return MATIC_OT;
    }
    //USDT_DCA
    if((tokens[0].address == USDT && tokens[1].address == DCA) || (tokens[1].address == USDT && tokens[0].address == DCA)){
        return USDT_DCA;
    }
    //USDC_SPACE
    if((tokens[0].address == USDC && tokens[1].address == SPACE) || (tokens[1].address == USDC && tokens[0].address == SPACE)){
        return USDC_SPACE;
    }
    //USDC_ZAT
    if((tokens[0].address == USDC && tokens[1].address == ZAT) || (tokens[1].address == USDC && tokens[0].address == ZAT)){
        return USDC_ZAT;
    }
    //USDC_USDT
    if((tokens[0].address == USDC && tokens[1].address == USDT) || (tokens[1].address == USDC && tokens[0].address == USDT)){
        return USDC_USDT;
    }
    //AAI_WETH
    if((tokens[0].address == AAI && tokens[1].address == WETH) || (tokens[1].address == AAI && tokens[0].address == WETH)){
        return AAI_WETH;
    }
    //ZK_WETH
    if((tokens[0].address == ZK && tokens[1].address == WETH) || (tokens[1].address == ZK && tokens[0].address == WETH)){
        return ZK_WETH;
    }
    //USDCe_ZK
    if((tokens[0].address == USDCe && tokens[1].address == ZK) || (tokens[1].address == USDCe && tokens[0].address == ZK)){
        return USDCe_ZK;
    }




    if (PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined) {
      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [tokens[0].address]: {
          ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
          [tokens[1].address]: getCreate2Address(
              FACTORY_ADDRESS,
              keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
              INIT_CODE_HASH
          )
        }
      }
    }

    return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]



  }

  public constructor(tokenAmountA: TokenAmount, tokenAmountB: TokenAmount) {
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      Pair.getAddress(tokenAmounts[0].token, tokenAmounts[1].token),
      18,
      'SLP-V2',
      'SpaceSwap V2'
    )
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount]
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    return token.equals(this.token0) || token.equals(this.token1)
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(this.token0, this.token1, this.tokenAmounts[0].raw, this.tokenAmounts[1].raw)
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(this.token1, this.token0, this.tokenAmounts[1].raw, this.tokenAmounts[0].raw)
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.token0Price : this.token1Price
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0]
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1]
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.reserve0 : this.reserve1
  }

  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (JSBI.equal(this.reserve0.raw, ZERO) || JSBI.equal(this.reserve1.raw, ZERO)) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(inputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, _997)
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw)
    const denominator = JSBI.add(JSBI.multiply(inputReserve.raw, _1000), inputAmountWithFee)
    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.divide(numerator, denominator)
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(outputAmount.raw, this.reserveOf(outputAmount.token).raw)
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(outputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const numerator = JSBI.multiply(JSBI.multiply(inputReserve.raw, outputAmount.raw), _1000)
    const denominator = JSBI.multiply(JSBI.subtract(outputReserve.raw, outputAmount.raw), _997)
    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.add(JSBI.divide(numerator, denominator), ONE)
    )
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount
  ): TokenAmount {
    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY')
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    invariant(tokenAmounts[0].token.equals(this.token0) && tokenAmounts[1].token.equals(this.token1), 'TOKEN')

    let liquidity: JSBI
    if (JSBI.equal(totalSupply.raw, ZERO)) {
      liquidity = JSBI.subtract(sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)), MINIMUM_LIQUIDITY)
    } else {
      const amount0 = JSBI.divide(JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw), this.reserve0.raw)
      const amount1 = JSBI.divide(JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw), this.reserve1.raw)
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
    }
    if (!JSBI.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return new TokenAmount(this.liquidityToken, liquidity)
  }

  public getLiquidityValue(
    token: Token,
    totalSupply: TokenAmount,
    liquidity: TokenAmount,
    feeOn: boolean = false,
    kLast?: BigintIsh
  ): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    invariant(totalSupply.token.equals(this.liquidityToken), 'TOTAL_SUPPLY')
    invariant(liquidity.token.equals(this.liquidityToken), 'LIQUIDITY')
    invariant(JSBI.lessThanOrEqual(liquidity.raw, totalSupply.raw), 'LIQUIDITY')

    let totalSupplyAdjusted: TokenAmount
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply
    } else {
      invariant(!!kLast, 'K_LAST')
      const kLastParsed = parseBigintIsh(kLast)
      if (!JSBI.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI.multiply(this.reserve0.raw, this.reserve1.raw))
        const rootKLast = sqrt(kLastParsed)
        if (JSBI.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI.multiply(totalSupply.raw, JSBI.subtract(rootK, rootKLast))
          const denominator = JSBI.add(JSBI.multiply(rootK, FIVE), rootKLast)
          const feeLiquidity = JSBI.divide(numerator, denominator)
          totalSupplyAdjusted = totalSupply.add(new TokenAmount(this.liquidityToken, feeLiquidity))
        } else {
          totalSupplyAdjusted = totalSupply
        }
      } else {
        totalSupplyAdjusted = totalSupply
      }
    }

    return new TokenAmount(
      token,
      JSBI.divide(JSBI.multiply(liquidity.raw, this.reserveOf(token).raw), totalSupplyAdjusted.raw)
    )
  }
}
